import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Button, Card, Cell, Choice, ConfirmModal, ErrorPage, InfoPanel, Link, ModalLauncher, NoPermission, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { GET_ALL_ROLES, GetAllRolesResponse } from "./AllRoles";
import { useState } from "react";
import AddEmployeeModal from "./AddEmployeeModal";

/* Get Employees Query */
const GET_EMPLOYEES = gql`
query getAllAdmins {
  admins {
    id
    firstName
    lastName
    email
    bsEmployeeRole {
      id
      name
    }
  }
}
`;

type GetEmployeesResponse = {
  admins: Employee[] | null;
}

type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  bsEmployeeRole: {
    id: string;
    name: string;
  };
}

/* Change Role Mutation */
export const SET_ROLE = gql`
mutation setAdminRole($userId: ID!, $roleId: ID) {
  setAdminRole(userId: $userId, roleId: $roleId) {
    id
    firstName
    lastName
    email
    bsEmployeeRole {
      id
      name
    }
  }
}
`;

export type SetRoleResponse = {
  setAdminRole: Employee | null;
}

type ChangeRoleInput = {
  userId: string;
  roleId: string;
}

export default function AllEmployees() {
  const { addAlert } = useAlertState();
  const { state } = useAuthState();
  const [changes, setChanges] = useState<ChangeRoleInput | null>(null);
  const { data: employeesData, loading: employeesAreLoading, error: employeesError } = useQuery<GetEmployeesResponse>(GET_EMPLOYEES);
  const { data: rolesData, loading: rolesAreLoading, error: rolesError } = useQuery<GetAllRolesResponse>(GET_ALL_ROLES);
  const [setRole] = useMutation<SetRoleResponse>(SET_ROLE, {
    update(cache, { data }, { variables }) {
      if (data && variables?.roleId === null) {
        // The user is removed, so remove them from the cached query results
        const { admins } = cache.readQuery<GetEmployeesResponse>({ query: GET_EMPLOYEES }) || { admins: [] };

        const newAdmins = admins?.filter((a) => a.id !== variables.userId);

        cache.writeQuery({
          query: GET_EMPLOYEES,
          data: {
            admins: newAdmins,
          },
        });
      }
    },
  });

  const handleConfirmRoleChange = async () => {
    if (!changes) {
      return;
    }

    const { errors } = await setRole({
      variables: {
        userId: changes.userId,
        roleId: changes.roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to change role' description={errors[0].message} type='error' id={id} />;
      addAlert(id, alert);
    }
  }

  const confirmRoleChangeModal = () => {
    const user = employeesData?.admins?.find((e) => e.id === changes?.userId);
    const role = rolesData?.adminRoles?.find((r) => r.id === changes?.roleId);

    if (!user || !role) {
      return null;
    }

    return (
      <ConfirmModal title='Confirm Role Change' onConfirm={handleConfirmRoleChange} onCancel={() => { setChanges(null); }} confirmLabel='Confirm Change'>
        <View style={{ gap: '16px' }}>
          <StyledParagraph>You are changing the role of <span style={{ fontWeight: 600 }}>{user.firstName} {user.lastName}</span> to <span style={{ fontWeight: 600 }}>{role.name}</span>.</StyledParagraph>
          <StyledParagraph>Please confirm that this is the correct role for this user.</StyledParagraph>
          <InfoPanel type='info'>
            <StyledParagraph>Not sure what this role can do? <Link href={`/employee-roles/${role.id}`} target='_blank'>View its permissions here.</Link></StyledParagraph>
          </InfoPanel>
        </View>
      </ConfirmModal>
    );
  }

  const handleRemoveEmployee = async (userId: string) => {
    const { data, errors } = await setRole({
      variables: {
        userId: userId,
        roleId: null,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove employee' description={errors[0].message} type='error' id={id} />;
      addAlert(id, alert);
    }

    if (data) {
      const id = generateId();
      const alert = <StandardAlert title='Employee removed' type='success' id={id} />;
      addAlert(id, alert);
    }
  }

  const removeEmployeeModal = (
    <ConfirmModal title='Remove Employee?' onConfirm={handleRemoveEmployee} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This user will no longer have access to the admin application.</StyledParagraph>
        <StyledParagraph>You can always add them as an employee again later.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (!state.user?.bsEmployeePermissions?.canManageBSEmployees) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (employeesError || rolesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      {changes !== null &&
        <ModalLauncher modal={confirmRoleChangeModal()}>
          {({ openModal }) => (
            <AutoOpen openModal={openModal} />
          )}
        </ModalLauncher>
      }

      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Employees</StyledHeading>
          <ModalLauncher modal={<AddEmployeeModal />}>
            {({ openModal }) => (
              <Button label='Add Employee' variant='primary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {(employeesAreLoading || rolesAreLoading) ?
            <View>
              <AtomSpinner size='medium' />
            </View>
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>User</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Role</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {employeesData?.admins?.map((e) => {
                  return (
                    <TableRow key={e.id}>
                      <TableCell><Link href={`/users/${e.id}`}>{e.firstName} {e.lastName}</Link></TableCell>
                      <TableCell>{e.email}</TableCell>
                      <TableCell style={{ padding: '8px 0' }}>
                        <SingleSelect name={`user-${e.id}-role`} value={e.bsEmployeeRole.id} style={{ maxWidth: '250px', minWidth: '250px' }} filterable onChange={(_, value) => { setChanges({ userId: e.id, roleId: value || '' }); }} disabled={e.id === state.user?.id}>
                          {rolesData?.adminRoles?.map((role) => (
                            <Choice label={role.name} description={role.description || undefined} value={role.id} disabled={role.id === e.bsEmployeeRole.id} key={role.id} />
                          ))}
                        </SingleSelect>
                      </TableCell>
                      <TableCell>
                        <ModalLauncher modal={removeEmployeeModal}>
                          {({ openModal }) => (
                            <Button label='Remove' variant='tertiary' role='button' action={() => { openModal(e.id); }} destructive disabled={e.id === state.user?.id} />
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}