import { ExternalRedirect, HasEmployeePermission, Header, Icons, Sidebar, SidebarItem, SidebarSection, useAlertState, useAuthState, View } from '@barscience/global-components';
import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AllErrors from './pages/errors/AllErrors';
import ErrorDetails from './pages/errors/ErrorDetails';
import AllFeatureFlags from './pages/featureFlags/AllFeatureFlags';
import FeatureFlagDetails from './pages/featureFlags/FeatureFlagDetails';
import UserSearch from './pages/users/UserSearch';
import UserDetails from './pages/users/UserDetails';
import AllProducts from './pages/products/AllProducts';
import ProductDetails from './pages/products/ProductDetails';
import AllEmployees from './pages/employees/AllEmployees';
import AllRoles from './pages/employees/AllRoles';
import RoleDetails from './pages/employees/RoleDetails';
import APIExplorer from './pages/graphql/APIExplorer';
import Tools from './pages/tools/Tools';
import PlanDetails from './pages/products/PlanDetails';
import OrgDetails from './pages/orgs/OrgDetails';
import OrgUsers from './pages/orgs/OrgUsers';
import OrgPaymentMethods from './pages/orgs/OrgPaymentMethods';
import OrgInvoices from './pages/orgs/OrgInvoices';
import OrgSearch from './pages/orgs/OrgSearch';
import NewOrgs from './pages/orgs/NewOrgs';

function App() {
  const location = useLocation();
  const { state } = useAuthState();
  const { alerts } = useAlertState();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  if (!state.user && !location.pathname.includes('/graphql')) {
    let authUrl = 'https://auth.barscience.us/login';
    if (window.location.href.includes('-dev')) {
      authUrl = 'https://auth-dev.barscience.us/login';
    } else if (!window.location.href.includes('barscience.us')) {
      authUrl = `http://${window.location.hostname}:6002/login`;
    }
    return (
      <ExternalRedirect to={authUrl + '?continue=' + window.location.href} />
    );
  }

  if (!state.user?.isBSEmployee && !location.pathname.includes('/graphql')) {
    return (
      <ExternalRedirect to='https://start.barscience.us' />
    );
  }

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh' }}>
        <Header product='admin' onSidebarClick={toggleSidebar} />

        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <Sidebar isOpen={sidebarIsOpen}>
            <HasEmployeePermission permissions={['canLookupUsers', 'canManageBSEmployees', 'canManageBSEmployeeRoles']}>
              <SidebarSection label='Accounts'>
                <HasEmployeePermission permissions={['canLookupUsers']}>
                  <SidebarItem label='Users' to='/users' icon={Icons.User} includeSubpaths />
                  <SidebarItem label='Orgs' to='/orgs' icon={Icons.Organization} includeSubpaths />
                </HasEmployeePermission>

                <HasEmployeePermission permissions={['canManageBSEmployees']}>
                  <SidebarItem label='Employees' to='/employees' icon={Icons.UserTie} includeSubpaths />
                </HasEmployeePermission>

                <HasEmployeePermission permissions={['canManageBSEmployeeRoles']}>
                  <SidebarItem label='Roles' to='/employee-roles' icon={Icons.UserGroup} includeSubpaths />
                </HasEmployeePermission>
              </SidebarSection>
            </HasEmployeePermission>

            <HasEmployeePermission permissions={['canViewLoggedErrors', 'canManageFeatureFlags']}>
              <SidebarSection label='Devs'>
                <HasEmployeePermission permissions={['canViewLoggedErrors', 'canDeleteLoggedErrors']}>
                  <SidebarItem label='Debug Errors' to='/errors' icon={Icons.Bug} includeSubpaths />
                </HasEmployeePermission>

                <HasEmployeePermission permissions={['canManageFeatureFlags']}>
                  <SidebarItem label='Feature Flags' to='/feature-flags' icon={Icons.Flag} includeSubpaths />
                </HasEmployeePermission>

                <HasEmployeePermission permissions={['canRunGqlQueries']}>
                  <SidebarItem label='API Explorer' to='/graphql' icon={Icons.CodeTerminal} includeSubpaths />
                </HasEmployeePermission>
              </SidebarSection>
            </HasEmployeePermission>

            <HasEmployeePermission permissions={['canEditProducts', 'canDeleteProducts', 'canEditProductRoles', 'canDeleteProductRoles']}>
              <SidebarSection label='Admins'>
                <HasEmployeePermission permissions={['canEditProducts']}>
                  <SidebarItem label='Products' to='/products' icon={Icons.AppGrid} includeSubpaths />
                </HasEmployeePermission>
              </SidebarSection>
            </HasEmployeePermission>

            <SidebarSection label='Resources'>
              <SidebarItem label='Tools' to='/tools' icon={Icons.Wrench} includeSubpaths />
            </SidebarSection>
          </Sidebar>

          <Routes>
            <Route path='/users' element={<UserSearch />} />
            <Route path='/users/:userId' element={<UserDetails />} />

            <Route path='/orgs' element={<OrgSearch />} />
            <Route path='/orgs/:orgId' element={<OrgDetails />} />
            <Route path='/orgs/:orgId/users' element={<OrgUsers />} />
            <Route path='/orgs/:orgId/payment-methods' element={<OrgPaymentMethods />} />
            <Route path='/orgs/:orgId/invoices' element={<OrgInvoices />} />
            <Route path='/new-orgs' element={<NewOrgs />} />

            <Route path='/employees' element={<AllEmployees />} />
            <Route path='/employee-roles' element={<AllRoles />} />
            <Route path='/employee-roles/:roleId' element={<RoleDetails />} />

            <Route path='/errors' element={<AllErrors />} />
            <Route path='/errors/:errorId' element={<ErrorDetails />} />

            <Route path='/feature-flags' element={<AllFeatureFlags />} />
            <Route path='/feature-flags/:flagId' element={<FeatureFlagDetails />} />

            <Route path='/products' element={<AllProducts />} />
            <Route path='/products/:productId' element={<ProductDetails />} />
            <Route path='/plans/:id' element={<PlanDetails />} />

            <Route path='/graphql' element={<APIExplorer />} />

            <Route path='/tools' element={<Tools />} />
          </Routes>
        </View>
      </View>
    </>
  );
}

export default App;
