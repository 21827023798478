import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Colors, ConfirmModal, HasEmployeePermission, ModalLauncher, NoPermission, NotFound, Row, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";

/* Get Error Details Query */
const GET_ERROR_DETAILS = gql`
query getDebugErrorDetails($id: Int!) {
  debugError(id: $id) {
    id
    description
    callStack
    origin
    referrer
    userAgent
    service
    user {
      id
      firstName
      lastName
    }
  }
}
`;

type GetErrorDetailsResponse = {
  debugError: DebugError | null;
}

type DebugError = {
  id: string;
  description: string;
  callStack: string;
  origin: string | null;
  referrer: string | null;
  userAgent: string | null;
  service: string | null;
  user: User | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
}

/* Delete Error Mutation */
const DELETE_ERROR = gql`
mutation deleteDebugError($id: Int!) {
  success: deleteDebugError(id: $id)
}
`;

type DeleteErrorResponse = {
  success: boolean;
}

export default function ErrorDetails() {
  const { state } = useAuthState();
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const { errorId } = useParams();
  const { data: errorData, loading: errorIsLoading } = useQuery<GetErrorDetailsResponse>(GET_ERROR_DETAILS, {
    variables: {
      id: parseFloat(errorId || ''),
    },
  });
  const [deleteError] = useMutation<DeleteErrorResponse>(DELETE_ERROR);

  const handleCopyLink = () => {
    const link = `https://admin.barscience.us/errors/${errorData?.debugError?.id}`;
    navigator.clipboard.writeText(link);

    const alertId = generateId();
    const alert = (
      <StandardAlert title='Link copied' type='success' id={alertId} />
    );
    addAlert(alertId, alert);
  }

  const handleDeleteError = async () => {
    const { data } = await deleteError({
      variables: {
        id: parseInt(errorId || ''),
      },
    });

    if (data?.success) {
      navigate('/errors');
    }
  }

  const confirmDeleteModal = (
    <ConfirmModal title='Delete Error?' onConfirm={handleDeleteError} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>The error will be permanently deleted.</StyledParagraph>
        <StyledParagraph>Make sure the error details are logged in a Jira ticket before deleting, if needed.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (!state.user?.bsEmployeePermissions?.canViewLoggedErrors) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (errorIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!errorData) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Debug Errors' to='/errors' />
          <Breadcrumb label={'#' + errorData.debugError?.id} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Error Details</StyledHeading>
          <ModalLauncher modal={confirmDeleteModal}>
            {({ openModal: openDeleteModal }) => (
              <ActionMenu alignment='right'>
                <ActionItem label='Copy Link' onClick={handleCopyLink} />
                <HasEmployeePermission permissions={['canDeleteLoggedErrors']}>
                  <ActionItem label='Delete' onClick={openDeleteModal} />
                </HasEmployeePermission>
              </ActionMenu>
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h5'>Error Info</StyledHeading>
              <View style={{ gap: '4px' }}>
                <StyledParagraph bold>Public Description</StyledParagraph>
                <StyledParagraph>{errorData.debugError?.description}</StyledParagraph>
              </View>
              <View style={{ gap: '4px' }}>
                <StyledParagraph bold>Call Stack</StyledParagraph>
                <StyledParagraph>{errorData.debugError?.callStack.split('[')[0]}</StyledParagraph>
                <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px' }}>{'[' + errorData.debugError?.callStack.split('[')[1]}</StyledParagraph>
              </View>
              <View style={{ gap: '4px' }}>
                <StyledParagraph bold>Service</StyledParagraph>
                <StyledParagraph>{errorData.debugError?.service || 'Unknown'}</StyledParagraph>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h5'>Request Info</StyledHeading>
              <View style={{ gap: '4px' }}>
                <StyledParagraph bold>Origin</StyledParagraph>
                <StyledParagraph>{errorData.debugError?.origin || 'Unknown'}</StyledParagraph>
              </View>
              <View style={{ gap: '4px' }}>
                <StyledParagraph bold>Referrer</StyledParagraph>
                <StyledParagraph>{errorData.debugError?.referrer || 'Unknown'}</StyledParagraph>
              </View>
              <View style={{ gap: '4px' }}>
                <StyledParagraph bold>User Agent</StyledParagraph>
                <StyledParagraph>{errorData.debugError?.userAgent || 'Unknown'}</StyledParagraph>
              </View>
            </View>
          </Card>
        </Cell>
      </Row>
      <Cell lg={6} md={4} sm={4}>
        <Card size='medium'>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h5'>User Info</StyledHeading>
            {errorData.debugError?.user ?
              <>
                <View style={{ gap: '4px' }}>
                  <StyledParagraph bold>ID</StyledParagraph>
                  <StyledParagraph>{errorData.debugError?.user?.id}</StyledParagraph>
                </View>
                <View style={{ gap: '4px' }}>
                  <StyledParagraph bold>Name</StyledParagraph>
                  <StyledParagraph>{errorData.debugError?.user?.firstName} {errorData.debugError?.user?.lastName}</StyledParagraph>
                </View>
              </>
              :
              <StyledParagraph>No linked user.</StyledParagraph>
            }
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}