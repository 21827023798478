import { Reference, gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Colors, ConfirmModal, ErrorPage, FormModal, HasEmployeePermission, Icon, Icons, Link, ModalLauncher, NoPermission, NotFound, Row, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextArea, TextField, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";

/* Get Role Query */
const GET_ROLE = gql`
query getAdminRoleDetails($id: ID!) {
  adminRole(id: $id) {
    id
    name
    description
    permissions {
      canLookupUsers
      canRunGqlQueries
      canEditUserProfiles
      canChangeUserPasswords
      canSuspendUsers
      canImpersonateUsers
      canEditUserProductAccess
      canEditCompanyProductAccess
      canViewLoggedErrors
      canDeleteLoggedErrors
      canDeleteUsers
      canEditOrganizationProfiles
      canEditOrganizationContacts
      canEditProducts
      canDeleteProducts
      canEditProductPlans
      canDeleteProductPlans
      canEditProductRoles
      canDeleteProductRoles
      canManageBSEmployeeRoles
      canManageBSEmployees
      canManageFeatureFlags
      canViewBillingSummary
      canViewOrgBillingDetails
      canEditOrgBillingDetails
      canIssueRefunds
    }
  }
}
`;

type GetRoleResponse = {
  adminRole: Role | null;
}

type Role = {
  id: string;
  name: string;
  description: string | null;
  permissions: RolePermissions;
}

type RolePermissions = {
  canLookupUsers: boolean;
  canRunGqlQueries: boolean;
  canEditUserProfiles: boolean;
  canChangeUserPasswords: boolean;
  canSuspendUsers: boolean;
  canImpersonateUsers: boolean;
  canEditUserProductAccess: boolean;
  canEditCompanyProductAccess: boolean;
  canViewLoggedErrors: boolean;
  canDeleteLoggedErrors: boolean;
  canDeleteUsers: boolean;
  canEditOrganizationProfiles: boolean;
  canEditOrganizationContacts: boolean;
  canEditProducts: boolean;
  canDeleteProducts: boolean;
  canEditProductPlans: boolean;
  canDeleteProductPlans: boolean;
  canEditProductRoles: boolean;
  canDeleteProductRoles: boolean;
  canManageBSEmployeeRoles: boolean;
  canManageBSEmployees: boolean;
  canManageFeatureFlags: boolean;
  canViewBillingSummary: boolean;
  canViewOrgBillingDetails: boolean;
  canEditOrgBillingDetails: boolean;
  canIssueRefunds: boolean;
}

/* Users Query */
const GET_USERS_WITH_ROLE = gql`
query getAdminsWithRole($roleId: ID!) {
  adminsWithRole(roleId: $roleId) {
    id
    firstName
    lastName
  }
}
`;

type GetUsersWithRoleResponse = {
  adminsWithRole: User[];
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
}

/* Edit Role Mutation */
const EDIT_ROLE = gql`
mutation editAdminRole($id: ID!, $name: String!, $description: String) {
  editAdminRole(id: $id, name: $name, description: $description) {
    id
    name
    description
  }
}
`;

type EditRoleResponse = {
  editAdminRole: {
    id: string;
    name: string;
    description: string | null;
  } | null;
}

type EditRoleInput = {
  roleName: string;
  description: string | null;
}

/* Edit Permissions Mutation */
const EDIT_PERMISSIONS = gql`
mutation editAdminRolePermissions($id: ID!, $permissions: BSEmployeePermissionsInput!) {
  editAdminRolePermissions(id: $id, permissions: $permissions) {
    id
    permissions {
      canLookupUsers
      canRunGqlQueries
      canEditUserProfiles
      canChangeUserPasswords
      canSuspendUsers
      canImpersonateUsers
      canEditUserProductAccess
      canEditCompanyProductAccess
      canViewLoggedErrors
      canDeleteLoggedErrors
      canDeleteUsers
      canEditOrganizationProfiles
      canEditOrganizationContacts
      canEditProducts
      canDeleteProducts
      canEditProductPlans
      canDeleteProductPlans
      canEditProductRoles
      canDeleteProductRoles
      canManageBSEmployeeRoles
      canManageBSEmployees
      canManageFeatureFlags
      canViewBillingSummary
      canViewOrgBillingDetails
      canEditOrgBillingDetails
      canIssueRefunds
    }
  }
}
`;

type EditPermissionsResponse = {
  editAdminRolePermissions: {
    id: string;
    permissions: RolePermissions;
  } | null;
}

const DEFAULT_PERMISSIONS: RolePermissions = {
  canLookupUsers: false,
  canRunGqlQueries: false,
  canEditUserProfiles: false,
  canChangeUserPasswords: false,
  canSuspendUsers: false,
  canImpersonateUsers: false,
  canEditUserProductAccess: false,
  canEditCompanyProductAccess: false,
  canViewLoggedErrors: false,
  canDeleteLoggedErrors: false,
  canDeleteUsers: false,
  canEditOrganizationProfiles: false,
  canEditOrganizationContacts: false,
  canEditProducts: false,
  canDeleteProducts: false,
  canEditProductPlans: false,
  canDeleteProductPlans: false,
  canEditProductRoles: false,
  canDeleteProductRoles: false,
  canManageBSEmployeeRoles: false,
  canManageBSEmployees: false,
  canManageFeatureFlags: false,
  canViewBillingSummary: false,
  canViewOrgBillingDetails: false,
  canEditOrgBillingDetails: false,
  canIssueRefunds: false,
};

/* Delete Role Mutation */
const DELETE_ROLE = gql`
mutation deleteAdminRole($id: ID!) {
  deleteAdminRole(id: $id) {
    id
  }
}
`;

type DeleteRoleResponse = {
  deleteAdminRole: {
    id: string;
  } | null;
}

/* Permission Maps */
type PermissionList = {
  id: keyof RolePermissions;
  label: string;
  category: 'Users' | 'Orgs' | 'Billing' | 'Devs' | 'Admins';
}

const PERMISSIONS: PermissionList[] = [
  {
    id: 'canLookupUsers',
    label: 'Lookup Users and Orgs',
    category: 'Users',
  },
  {
    id: 'canEditUserProfiles',
    label: 'Edit User Profiles',
    category: 'Users',
  },
  {
    id: 'canChangeUserPasswords',
    label: 'Change User Passwords',
    category: 'Users',
  },
  {
    id: 'canEditUserProductAccess',
    label: 'Edit User Product Access',
    category: 'Users',
  },
  {
    id: 'canSuspendUsers',
    label: 'Suspend Users',
    category: 'Users',
  },
  {
    id: 'canImpersonateUsers',
    label: 'Impersonate Users',
    category: 'Users',
  },
  {
    id: 'canDeleteUsers',
    label: 'Delete Users',
    category: 'Users',
  },
  {
    id: 'canEditOrganizationProfiles',
    label: 'Edit Org Profiles',
    category: 'Orgs',
  },
  {
    id: 'canEditOrganizationContacts',
    label: 'Edit Org Contacts',
    category: 'Orgs',
  },
  {
    id: 'canEditCompanyProductAccess',
    label: 'Edit Org Subscriptions',
    category: 'Users',
  },
  {
    id: 'canViewBillingSummary',
    label: 'View Billing Dashboards',
    category: 'Billing',
  },
  {
    id: 'canViewOrgBillingDetails',
    label: 'View Org Billing Info',
    category: 'Billing',
  },
  {
    id: 'canEditOrgBillingDetails',
    label: 'Edit Org Billing Info',
    category: 'Billing',
  },
  {
    id: 'canIssueRefunds',
    label: 'Issue Refunds',
    category: 'Billing',
  },
  {
    id: 'canRunGqlQueries',
    label: 'Run GQL Queries',
    category: 'Devs',
  },
  {
    id: 'canViewLoggedErrors',
    label: 'View Logged Errors',
    category: 'Devs',
  },
  {
    id: 'canDeleteLoggedErrors',
    label: 'Delete Logged Errors',
    category: 'Devs',
  },
  {
    id: 'canManageFeatureFlags',
    label: 'Manage Feature Flags',
    category: 'Devs',
  },
  {
    id: 'canManageBSEmployees',
    label: 'Manage BS Employees',
    category: 'Admins',
  },
  {
    id: 'canManageBSEmployeeRoles',
    label: 'Manage BS Employee Roles',
    category: 'Admins',
  },
  {
    id: 'canEditProducts',
    label: 'Edit Products',
    category: 'Admins',
  },
  {
    id: 'canDeleteProducts',
    label: 'Delete Products',
    category: 'Admins',
  },
  {
    id: 'canEditProductPlans',
    label: 'Edit Product Plans',
    category: 'Admins',
  },
  {
    id: 'canDeleteProductPlans',
    label: 'Delete Product Plans',
    category: 'Admins',
  },
  {
    id: 'canEditProductRoles',
    label: 'Edit Product Roles',
    category: 'Admins',
  },
  {
    id: 'canDeleteProductRoles',
    label: 'Delete Product Roles',
    category: 'Admins',
  },
];

export default function RoleDetails() {
  const navigate = useNavigate();
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { roleId } = useParams();
  const { data: roleData, loading: roleIsLoading, error: roleError } = useQuery<GetRoleResponse>(GET_ROLE, {
    variables: {
      id: roleId,
    },
  });
  const { data: usersData, loading: usersAreLoading, error: usersError } = useQuery<GetUsersWithRoleResponse>(GET_USERS_WITH_ROLE, {
    variables: {
      roleId: roleId,
    },
  });
  const [editRole] = useMutation<EditRoleResponse>(EDIT_ROLE);
  const [editPermissions] = useMutation<EditPermissionsResponse>(EDIT_PERMISSIONS);
  const [deleteRole] = useMutation<DeleteRoleResponse>(DELETE_ROLE, {
    update(cache, { data }) {
      if (data?.deleteAdminRole) {
        cache.modify({
          fields: {
            adminRoles(existingRoles = []) {
              return existingRoles.filter((roleRef: Reference) => roleRef.__ref !== cache.identify(data.deleteAdminRole || {}));
            },
          },
        });
      }
    },
  });

  const handleEditRole = async (values: EditRoleInput) => {
    await editRole({
      variables: {
        id: roleId,
        name: values.roleName,
        description: values.description,
      },
    });
  }

  const editRoleModal = (
    <FormModal<EditRoleInput> title='Edit Role' onSubmit={handleEditRole} initialValues={{ roleName: roleData?.adminRole?.name || '', description: roleData?.adminRole?.description || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Role Name' name='roleName' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  const handleEditPermissions = async (values: RolePermissions) => {
    let permissions = values as any;
    delete permissions.__typename;

    await editPermissions({
      variables: {
        id: roleId,
        permissions: values,
      },
    });
  }

  const editPermissionsModal = (
    <FormModal<RolePermissions> title='Edit Permissions' onSubmit={handleEditPermissions} initialValues={roleData?.adminRole?.permissions || DEFAULT_PERMISSIONS}>
      <View style={{ gap: '16px' }}>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Users</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Users').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.adminRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Orgs</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Orgs').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.adminRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Billing</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Billing').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.adminRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Devs</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Devs').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.adminRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Admins</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Admins').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.adminRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
      </View>
    </FormModal >
  );

  const handleDeleteRole = async () => {
    const { data, errors } = await deleteRole({
      variables: {
        id: roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to delete role' description={errors[0].message} type='error' id={id} />
      );
      addAlert(id, alert);
    }

    if (data?.deleteAdminRole) {
      navigate('/employee-roles');
    }
  }

  const deleteRoleModal = (
    <ConfirmModal title='Delete Role?' onConfirm={handleDeleteRole} confirmLabel='Delete' destructive>
      <StyledParagraph>This role will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  if (!state.user?.bsEmployeePermissions?.canManageBSEmployeeRoles && !state.user?.bsEmployeePermissions?.canManageBSEmployees) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (roleIsLoading || usersAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <View>
            <AtomSpinner size='medium' />
          </View>
        </Cell>
      </StandardGrid>
    );
  }

  if (roleError && roleError.graphQLErrors[0]?.extensions?.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (roleError || !roleData?.adminRole || usersError || !usersData?.adminsWithRole) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Roles' to='/employee-roles' />
          <Breadcrumb label={roleData.adminRole.name} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '4px' }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
            <View style={{ flexDirection: 'row', gap: '16px' }}>
              <StyledHeading tag='h3'>{roleData.adminRole.name}</StyledHeading>
              <HasEmployeePermission permissions={['canManageBSEmployeeRoles']}>
                <ModalLauncher modal={editRoleModal}>
                  {({ openModal }) => (
                    <Button label='Edit' variant='tertiary' role='button' action={openModal} />
                  )}
                </ModalLauncher>
              </HasEmployeePermission>
            </View>

            {usersData.adminsWithRole.length === 0 &&
              <HasEmployeePermission permissions={['canManageBSEmployeeRoles']}>
                <ModalLauncher modal={deleteRoleModal}>
                  {({ openModal }) => (
                    <ActionMenu alignment='right'>
                      <ActionItem label='Delete' onClick={openModal} />
                    </ActionMenu>
                  )}
                </ModalLauncher>
              </HasEmployeePermission>
            }
          </View>

          <StyledParagraph style={{ color: Colors.neutral700 }}>{roleData.adminRole.description}</StyledParagraph>
        </View>
      </Cell >
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '24px' }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledHeading tag='h5'>Permissions</StyledHeading>
                <HasEmployeePermission permissions={['canManageBSEmployeeRoles']}>
                  <ModalLauncher modal={editPermissionsModal}>
                    {({ openModal }) => (
                      <Button label='Edit' variant='tertiary' role='button' action={openModal} />
                    )}
                  </ModalLauncher>
                </HasEmployeePermission>
              </View>

              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Users</StyledParagraph>
                <View style={{ gap: '4px' }}>
                  {PERMISSIONS.filter(permission => permission.category === 'Users').map(permission => (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                      <StyledParagraph>{permission.label}</StyledParagraph>
                      {roleData.adminRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Orgs</StyledParagraph>
                <View style={{ gap: '4px' }}>
                  {PERMISSIONS.filter(permission => permission.category === 'Orgs').map(permission => (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                      <StyledParagraph>{permission.label}</StyledParagraph>
                      {roleData.adminRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Billing</StyledParagraph>
                <View style={{ gap: '4px' }}>
                  {PERMISSIONS.filter(permission => permission.category === 'Billing').map(permission => (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                      <StyledParagraph>{permission.label}</StyledParagraph>
                      {roleData.adminRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Devs</StyledParagraph>
                <View style={{ gap: '4px' }}>
                  {PERMISSIONS.filter(permission => permission.category === 'Devs').map(permission => (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                      <StyledParagraph>{permission.label}</StyledParagraph>
                      {roleData.adminRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ gap: '8px' }}>
                <StyledParagraph bold>Admins</StyledParagraph>
                <View style={{ gap: '4px' }}>
                  {PERMISSIONS.filter(permission => permission.category === 'Admins').map(permission => (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                      <StyledParagraph>{permission.label}</StyledParagraph>
                      {roleData.adminRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '24px' }}>
              <StyledHeading tag='h5'>Users With Role</StyledHeading>

              {usersData.adminsWithRole.length > 0 &&
                <View style={{ gap: '8px' }}>
                  {usersData.adminsWithRole.map((user) => {
                    return (
                      <StyledParagraph key={user.id}><Link href={`/users/${user.id}`}>{user.firstName} {user.lastName}</Link></StyledParagraph>
                    );
                  })}
                </View>
              }

              {usersData.adminsWithRole.length === 0 && <StyledParagraph>There are no users with this role.</StyledParagraph>}
            </View>
          </Card>
        </Cell>
      </Row>
    </StandardGrid>
  );
}