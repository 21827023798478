import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, ErrorPage, FormModal, Link, ModalLauncher, NoPermission, StandardAlert, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { useNavigate } from "react-router-dom";

/* Get Roles Query */
export const GET_ALL_ROLES = gql`
query getAllAdminRoles {
  adminRoles {
    id
    name
    description
  }
}
`;

export type GetAllRolesResponse = {
  adminRoles: Role[] | null;
}

type Role = {
  id: string;
  name: string;
  description: string | null;
}

/* Create Role Mutation */
const CREATE_ROLE = gql`
mutation createAdminRole($name: String!, $description: String) {
  createAdminRole(name: $name, description: $description) {
    id
    name
    description
  }
}
`;

type CreateRoleResponse = {
  createAdminRole: Role | null;
}

type CreateRoleInput = {
  roleName: string;
  description: string;
}

export default function AllRoles() {
  const navigate = useNavigate();
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: roleData, loading: rolesAreLoading, error: rolesError } = useQuery<GetAllRolesResponse>(GET_ALL_ROLES);
  const [createRole] = useMutation<CreateRoleResponse>(CREATE_ROLE, {
    update(cache, { data }) {
      if (data?.createAdminRole) {
        cache.modify({
          fields: {
            adminRoles(existingRoles = [], { readField }) {
              const newRoleRef = cache.writeFragment({
                data: data.createAdminRole,
                fragment: gql`
                  fragment NewRole on AdminRole {
                    id
                    name
                    description
                  }
                `
              });



              return [...existingRoles, newRoleRef].sort((a, b) => {
                const aName = readField<string>('name', a);
                const bName = readField<string>('name', b);

                if (!aName || !bName) return 0;
                
                return aName.localeCompare(bName);
              });
            }
          }
        });
      }
    },
  });

  const handleCreateRole = async (values: CreateRoleInput) => {
    const { data, errors } = await createRole({
      variables: {
        name: values.roleName,
        description: values.description,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to create role' description={errors[0].message} id={id} />
      );
      addAlert(id, alert);
    }

    if (data?.createAdminRole) {
      navigate(`/employee-roles/${data.createAdminRole.id}`);
    }
  }

  const createRoleModal = (
    <FormModal<CreateRoleInput> title='Create Role' onSubmit={handleCreateRole} submitLabel='Create' initialValues={{ roleName: '', description: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Role Name' name='roleName' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  if (!state.user?.bsEmployeePermissions?.canManageBSEmployeeRoles && !state.user?.bsEmployeePermissions?.canManageBSEmployees) {
    return <NoPermission />;
  }

  if (rolesError || (!rolesAreLoading && !roleData?.adminRoles)) {
    return <ErrorPage />;
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Employee Roles</StyledHeading>
          <ModalLauncher modal={createRoleModal}>
            {({ openModal }) => (
              <Button label='Create Role' variant='primary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {rolesAreLoading ?
            <View>
              <AtomSpinner size='medium' />
            </View>
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Role Name</TableHeaderCell>
                  <TableHeaderCell>Description</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {roleData?.adminRoles?.map((role) => {
                  return (
                    <TableRow key={role.id}>
                      <TableCell><Link href={`/employee-roles/${role.id}`}>{role.name}</Link></TableCell>
                      <TableCell>{role.description}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}