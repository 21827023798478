import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Choice, CircularSpinner, ErrorPage, Form, Icons, Link, MultiSelect, NoPermission, OptionBar, OptionItem, PageButtons, StandardGrid, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, useAuthState, useForm } from "@barscience/global-components";
import { useParams, useSearchParams } from "react-router-dom";

/* Get Org Profile Query */
export const GET_ORG_PROFILE = gql`
query getOrgProfileForEmployeesPage($orgId: ID!) {
  org(id: $orgId) {
    id
    name
  }
}
`;

export type GetOrgProfileResponse = {
  org: {
    id: string;
    name: string;
  } | null;
}

/* Get All Employees Query */
const GET_ALL_EMPLOYEES = gql`
query getUsersForOrg($orgId: ID!, $page: Int!, $isInactive: Boolean, $name: String, $products: [ID!]) {
  usersForOrg(orgId: $orgId, page: $page, isInactive: $isInactive, name: $name, products: $products) {
    id
    firstName
    lastName
    email
    phone
  }
}
`;

type GetAllEmployeesResponse = {
  usersForOrg: User[] | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

type SearchFormInput = {
  name: string;
}

/* Get Org Products Query */
const GET_ORG_PRODUCTS = gql`
query getOrgProducts($orgId: ID!) {
  productsForOrg(orgId: $orgId) {
    id
    name
  }
}
`;

type GetOrgProductsResponse = {
  productsForOrg: Product[] | null;
}

type Product = {
  id: string;
  name: string;
}

const USERS_PER_PAGE = 50;

export default function OrgUsers() {
  const { orgId } = useParams();
  const { state } = useAuthState();
  const [searchParams, setSearchParams] = useSearchParams({ page: '0', isInactive: 'false' });
  const { data: orgData, loading: orgIsLoading, error: orgError } = useQuery<GetOrgProfileResponse>(GET_ORG_PROFILE, {
    variables: {
      orgId: orgId,
    },
  });
  const { data: productsData, loading: productsAreLoading, error: productsError } = useQuery<GetOrgProductsResponse>(GET_ORG_PRODUCTS, {
    variables: {
      orgId: orgId,
    },
  });
  const { data: usersData, loading: usersAreLoading, error: usersError } = useQuery<GetAllEmployeesResponse>(GET_ALL_EMPLOYEES, {
    variables: {
      orgId: orgId,
      page: searchParams.get('page') ? parseInt(searchParams.get('page') || '0') : 0,
      isInactive: searchParams.get('isInactive') === 'true' ? true : false,
      name: searchParams.get('name') || null,
      products: searchParams.get('products') ? searchParams.get('products')?.split(',') : null,
    },
  });

  const isInactive = searchParams.get('isInactive') === 'true' ? true : false;
  const currentPage = searchParams.get('page') ? parseInt(searchParams.get('page') || '0') : 0;

  const handleActiveFilterChange = (value: string) => {
    searchParams.set('isInactive', value);
    setSearchParams(searchParams);
  }

  const handlePageChange = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  }

  const handleSearch = (values: SearchFormInput) => {
    let firstName = values.name.substring(0, values.name.lastIndexOf(' '));
    let lastName = values.name.substring(values.name.lastIndexOf(' ') + 1);

    if (firstName === '') {
      firstName = lastName;
    }

    if (lastName === '') {
      lastName = firstName;
    }

    searchParams.set('page', '0');
    searchParams.set('name', `${firstName}+${lastName}`);
    setSearchParams(searchParams);
  }

  const handleProductChange = (_: string, value: { [name: string]: boolean }) => {
    const selectedProducts = Object.keys(value).filter((key) => value[key]);

    searchParams.set('page', '0');
    searchParams.set('products', selectedProducts.join(','));
    setSearchParams(searchParams);
  }

  const getSelectedProducts = () => {
    const selectedProducts: { [name: string]: boolean } = {};
    productsData?.productsForOrg?.forEach((p) => {
      selectedProducts[p.id] = false;
    })

    const products = searchParams.get('products');
    if (products) {
      products.split(',').forEach((product) => {
        selectedProducts[product] = true;
      });
    }

    return selectedProducts;
  }

  const searchForm = useForm<SearchFormInput>({
    initialValues: {
      name: searchParams.get('name') || '',
    },
    onSubmit: handleSearch
  });

  const getNumPages = (): number => {
    if (usersData?.usersForOrg?.length === USERS_PER_PAGE) {
      return currentPage + 2;
    }

    return 1;
  }

  if (!state.user?.bsEmployeePermissions?.canLookupUsers) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (orgIsLoading || productsAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (usersError || orgError || productsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Orgs' to='/orgs' />
          <Breadcrumb label={orgData?.org?.name || ''} to={`/orgs/${orgId}`} />
          <Breadcrumb label='Users' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
            <Form handleSubmit={searchForm.handleSubmit}>
              <TextField name='name' icon={Icons.MagnifyingGlass} placeholder='Search by name' value={searchForm.values.name.replace('+', '')} error={searchForm.errors.name} onChange={searchForm.handleChange} style={{ width: '300px' }} />
            </Form>

            <OptionBar selectedValue={isInactive ? 'true' : 'false'} onChange={handleActiveFilterChange}>
              <OptionItem label='Active' value='false' />
              <OptionItem label='Inactive' value='true' />
            </OptionBar>

            <MultiSelect label='' name='products' value={getSelectedProducts()} onChange={handleProductChange} placeholder='Filter by product' entityLabel='products' style={{ marginTop: '-4px' }}>
              {productsData?.productsForOrg?.map((product) => (
                <Choice label={product.name} value={product.id} key={product.id} />
              ))}
            </MultiSelect>
          </View>

          <PageButtons currentPage={currentPage} onPageChange={handlePageChange} numPages={getNumPages()} />
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {usersAreLoading ?
            <View style={{ alignItems: 'center', width: '100%' }}>
              <CircularSpinner size='medium' />
            </View>
            :
            usersData?.usersForOrg?.length === 0 ?
              <StyledParagraph>No employees found.</StyledParagraph>
              :
              <View>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell style={{ minWidth: '200px', '@media (max-width: 767px)': { maxWidth: '200px' } }}>Name</TableHeaderCell>
                      <TableHeaderCell>Email</TableHeaderCell>
                      <TableHeaderCell>Phone</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {usersData?.usersForOrg?.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell><Link href={`/users/${user.id}`} linkStyle={{ maxWidth: '400px', minWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', '@media (max-width: 767px)': { maxWidth: '200px' } }}>{user.firstName} {user.lastName}</Link></TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </View>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}