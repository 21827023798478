import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Choice, Colors, FormModal, Icon, Icons, Link, ModalLauncher, NoPermission, Radio, RadioGroup, SingleSelect, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, useAuthState } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* Get All Feature Flags Query */
const GET_FEATURE_FLAGS = gql`
query getAllFeatureFlags {
  featureFlags {
    id
    description
    enabled
    createdAt
    service
  }
}
`;

type GetFeatureFlagsResponse = {
  featureFlags: FeatureFlag[];
}

type FeatureFlag = {
  id: string;
  description: string;
  enabled: boolean;
  createdAt: string;
  service: string;
}

/* Create Feature Flag Mutation */
const CREATE_FEATURE_FLAG = gql`
mutation createFeatureFlag($input: CreateFeatureFlagInput!) {
  createFeatureFlag(input: $input) {
    id
    description
    enabled
    createdAt
    service
    defaultValue
  }
}
`;

type CreateFeatureFlagResponse = {
  createFeatureFlag: {
    id: string;
    description: string;
    enabled: boolean;
    createdAt: string;
    service: string;
    defaultValue: boolean;
  } | null;
}

type CreateFeatureFlagInput = {
  id: string;
  defaultValue: string;
  service: string;
}

const styles = StyleSheet.create({
  flagLabelShared: {
    fontWeight: 'bold',
  },
  flagLabelEnabled: {
    color: Colors.primary500,
  },
  flagLabelDisabled: {
    color: Colors.error500,
  }
});

export default function AllFeatureFlags() {
  const navigate = useNavigate();
  const { state } = useAuthState();
  const { data: featureFlagData, loading: featureFlagsAreLoading } = useQuery<GetFeatureFlagsResponse>(GET_FEATURE_FLAGS);
  const [openSections, setOpenSections] = useState<{ [sectionName: string]: boolean }>({});
  const [createFeatureFlag] = useMutation<CreateFeatureFlagResponse>(CREATE_FEATURE_FLAG, {
    update(cache, { data }) {
      if (!data || !data.createFeatureFlag) {
        return;
      }

      cache.writeFragment({
        data: data.createFeatureFlag,
        fragment: gql`
          fragment NewFeatureFlag on FeatureFlag {
            id
            description
            enabled
            createdAt
            service
            defaultValue
          }
        `,
      });
    },
  });

  const handleCreateFlag = async (values: CreateFeatureFlagInput) => {
    let defaultValue = false;
    if (values.defaultValue === 'TRUE') {
      defaultValue = true;
    }

    const { data } = await createFeatureFlag({
      variables: {
        input: {
          id: values.id,
          description: '',
          defaultValue: defaultValue,
          service: values.service,
        },
      },
    });

    if (data?.createFeatureFlag?.id) {
      navigate(`/feature-flags/${data.createFeatureFlag.id}`);
    }
  }

  if (!state.user?.bsEmployeePermissions?.canManageFeatureFlags) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (featureFlagsAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  const validateFlagSlug = (_: string, value: string) => {
    if (!/^[A-Za-z0-9]+(?:_[A-Za-z0-9]+)*$/.test(value)) {
      return 'Invalid slug';
    }

    return null;
  }

  const createFlagModal = (
    <FormModal<CreateFeatureFlagInput> title='Create Feature Flag' submitLabel='Create Flag' onSubmit={handleCreateFlag} initialValues={{ id: '', defaultValue: 'FALSE', service: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Slug' description='A descriptive identifier for the flag. This cannot be changed after the flag is created.' name='id' validate={validateFlagSlug} required />
        <RadioGroup label='Default Value' description='This is returned to all users when the flag is disabled.' name='defaultValue' required>
          <Radio label='True' value='TRUE' />
          <Radio label='False' value='FALSE' />
        </RadioGroup>
        <SingleSelect label='Service' name='service' required>
          <Choice label='Admin' value='ADMIN' />
          <Choice label='General' value='GENERAL' />
          <Choice label='Inventory' value='INVENTORY' />
          <Choice label='Org' value='ORG' />
        </SingleSelect>
      </View>
    </FormModal>
  );

  const FlagGroup = (label: string, serviceId: string, isOpen: boolean) => {
    const overflowStyle: CSSProperties = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }

    return (
      <Card size='medium'>
        <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '8px', 'user-select': 'none' }} onClick={() => { setOpenSections({ ...openSections, [serviceId]: !isOpen }) }}>
          {isOpen && <Icon size='medium' icon={Icons.ChevronDown} />}
          {!isOpen && <Icon size='medium' icon={Icons.ChevronRight} />}
          <StyledHeading tag='h5'>{label}</StyledHeading>
        </View>

        {isOpen &&
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell style={{ maxWidth: '200px', minWidth: '200px', width: '200px', ...overflowStyle }}>ID</TableHeaderCell>
                <TableHeaderCell style={{ maxWidth: '200px', minWidth: '250px', width: '200px', ...overflowStyle }}>Created</TableHeaderCell>
                <TableHeaderCell style={{ maxWidth: '80px', minWidth: '80px', width: '80px', ...overflowStyle }}>Status</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {featureFlagData?.featureFlags.filter((flag) => flag.service === serviceId).map((flag, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell><Link href={'/feature-flags/' + flag.id} linkStyle={{ maxWidth: '200px', minWidth: '200px', width: '200px', ...overflowStyle }}>{flag.id}</Link></TableCell>
                    <TableCell style={{ maxWidth: '200px', minWidth: '250px', width: '200px', ...overflowStyle }}>{new Date(flag.createdAt).toLocaleString()}</TableCell>
                    <TableCell style={{ maxWidth: '80px', minWidth: '80px', width: '80px', ...overflowStyle }}>{flag.enabled ? <span className={css(styles.flagLabelShared, styles.flagLabelEnabled)}>ENABLED</span> : <span className={css(styles.flagLabelShared, styles.flagLabelDisabled)}>DISABLED</span>}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        }
      </Card>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Feature Flags</StyledHeading>
          <ModalLauncher modal={createFlagModal}>
            {({ openModal }) => (
              <Button label='Create Flag' variant='primary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {FlagGroup('Admin', 'ADMIN', openSections['ADMIN'])}
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {FlagGroup('General', 'GENERAL', openSections['GENERAL'])}
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {FlagGroup('Inventory', 'INVENTORY', openSections['INVENTORY'])}
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {FlagGroup('Org', 'ORG', openSections['ORG'])}
      </Cell>
    </StandardGrid>
  );
}