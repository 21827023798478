import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, FormModal, generateId, HasEmployeePermission, Icons, InfoPanel, Link, Modal, ModalBody, ModalFooter, ModalHeader, ModalLauncher, NoPermission, NotFound, PageButtons, Radio, RadioGroup, Row, SingleSelect, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextEditor, TextField, Tooltip, Typography, useAlertState, useAuthState, View } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";
import currency from "currency.js";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeAgo from 'react-timeago';
import { noteEditorConfig } from "../users/UserDetails";
import FlagCheck from "../../components/FlagCheck";

/* Org Details Query */
const GET_ORG_DETAILS = gql`
query getOrgDetailsForBSAdmins($id: ID!) {
  org(id: $id) {
    id
    name
    address {
      streetAddress
      city
      state
      zipcode
    }
    primaryContact {
      id
      firstName
      lastName
    }
    billingContact {
      id
      firstName
      lastName
    }
    created
    joinCode
    plans {
      plan {
        id
        name
        product {
          id
          name
        }
      }
      startDate
      nextBillingDate
      estimatedNextPrice
      countBillableUsers
      cancelledAt
      cancelEffectiveDate
    }
    stripeID
    stripeConnectAccount {
      stripeID
      hasChargesEnabled
      hasPayoutsEnabled
      isDisabled
      hasCompletedOnboarding
      hasMissingRequirements
      isUnderStripeReview
    }
    notes {
      id
      content
      createdBy {
        id
        firstName
        lastName
      }
      created
      lastUpdatedBy {
        id
        firstName
        lastName
      }
      lastUpdated
    }
  }
}
`;

type OrgDetailsQueryResponse = {
  org: Org | null;
}

type Org = {
  id: string;
  name: string;
  address: Address;
  primaryContact: Contact;
  billingContact: Contact;
  created: string;
  joinCode: string;
  plans: Plan[];
  stripeID: string;
  stripeConnectAccount: StripeConnectAccount | null;
  notes: Note[];
}

type StripeConnectAccount = {
  stripeID: string;
  hasChargesEnabled: boolean;
  hasPayoutsEnabled: boolean;
  isDisabled: boolean;
  hasCompletedOnboarding: boolean;
  hasMissingRequirements: boolean;
  isUnderStripeReview: boolean;
}

type Address = {
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
}

type Contact = {
  id: string;
  firstName: string;
  lastName: string;
}

type Plan = {
  plan: {
    id: string;
    name: string;
    product: {
      id: string;
      name: string;
    }
  };
  startDate: string;
  nextBillingDate: string;
  estimatedNextPrice: string;
  countBillableUsers: number;
  cancelledAt: string | null;
  cancelEffectiveDate: string | null;
}

type Note = {
  id: string;
  content: string;
  createdBy: Contact;
  created: string;
  lastUpdatedBy: Contact | null;
  lastUpdated: string | null;

}

/* Change Logs Query */
const GET_CHANGE_LOGS = gql`
query getOrgChangeLogs($id: ID!, $page: Int!) {
  org(id: $id) {
    id
    changeLogs(page: $page) {
      timestamp
      description
    }
  }
}
`;

type ChangeLogsQueryResponse = {
  org: {
    id: string;
    changeLogs: {
      timestamp: string;
      description: string;
    }[]
  } | null;
}

const CHANGE_LOGS_PER_PAGE = 10;

/* Edit Name Mutation */
const EDIT_NAME = gql`
mutation changeOrgName($id: ID!, $name: String!) {
  changeOrgName(id: $id, name: $name) {
    id
    name
  }
}
`;

type EditNameInput = {
  orgName: string;
}

/* Edit Address Mutation */
const EDIT_ADDRESS = gql`
mutation changeOrgAddress($id: ID!, $input: AddressInput!) {
  changeOrgAddress(id: $id, input: $input) {
    id
    address {
      city
      state
      streetAddress
      zipcode
    }
  }
}

`;

type EditAddressInput = {
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
}

/* Change Join Code Mutation */
const CHANGE_JOIN_CODE = gql`
mutation changeOrgJoinCode($id: ID!) {
  changeJoinCode(orgId: $id) {
    id
    joinCode
  }
}
`;

/* Change Primary Contact Mutation */
const CHANGE_PRIMARY_CONTACT = gql`
mutation changeOrgPrimaryContact($orgId: ID!, $userId: ID!) {
  changeOrgPrimaryContact(id: $orgId, primaryContactID: $userId) {
    id
    primaryContact {
      id
      firstName
      lastName
    }
  }
}
`;

type ChangeContactInput = {
  userId: string;
}

/* Change Billing Contact Mutation */
const CHANGE_BILLING_CONTACT = gql`
mutation changeOrgBillingContact($orgId: ID!, $userId: ID!) {
  changeOrgBillingContact(id: $orgId, billingContactID: $userId) {
    id
    billingContact {
      id
      firstName
      lastName
    }
  }
}
`;

/* Cancel Subscription Mutation */
const CANCEL_SUBSCRIPTION = gql`
mutation cancelSubscription($orgId: ID!, $planId: ID!, $cancelReason: CancelReason!, $feedback: String) {
  cancelSubscription(orgId: $orgId, planId: $planId, cancelReason: $cancelReason, feedback: $feedback) {
    cancelEffectiveDate
  }
}
`;

type CancelSubscriptionResponse = {
  cancelSubscription: {
    cancelEffectiveDate: string | null;
  } | null;
}

type CancelSubscriptionInput = {
  reason: string;
  feedback: string;
  planId: string;
}

/* Resume Subscription Mutation */
const RESUME_SUBSCRIPTION = gql`
mutation resumeCancelledOrgSubscription($orgId: ID!, $planId: ID!) {
  resumeSubscription(orgId: $orgId, planId: $planId) {
    plan {
      id
      name
      product {
        id
        name
      }
    }
    startDate
    nextBillingDate
    estimatedNextPrice
    countBillableUsers
    cancelledAt
    cancelEffectiveDate
  }
}
`;

type ResumeSubscriptionResponse = {
  resumeSubscription: Plan | null;
}

/* Get Product Plans Query */
const GET_PRODUCT_PLANS = gql`
query getProductPlans($productId: ID!) {
  plans(productId: $productId) {
    id
    name
    description
    cost
    costType
    isInactive
    isPublic
    type
    userLimit
  }
}
`;

type ProductPlansQueryResponse = {
  plans: {
    id: string;
    name: string;
    description: string | null;
    cost: string;
    costType: 'FLAT' | 'PER_USER';
    isInactive: boolean;
    isPublic: boolean;
    type: 'MONTHLY' | 'ANNUAL';
    userLimit: number | null;
  }[];
}

/* Preview Plan Change Query */
const PREVIEW_PLAN_CHANGE = gql`
query previewProductPlanChange($orgId: ID!, $newPlanId: ID!) {
  previewSubscriptionChange(orgId: $orgId, newPlanId: $newPlanId) {
    nextInvoiceAmount
    nextInvoiceDate
  }
}
`;

type PreviewPlanChangeResponse = {
  previewSubscriptionChange: {
    nextInvoiceAmount: string;
    nextInvoiceDate: string;
  }
}

/* Change Plan Mutation */
const CHANGE_PLAN = gql`
mutation changeSubscriptionPlan($orgId: ID!, $newPlanId: ID!) {
  changeSubscriptionPlan(orgId: $orgId, newPlanId: $newPlanId) {
    nextBillingDate
    estimatedNextPrice
  }
}
`;

/* Create Note Mutation */
const CREATE_NOTE = gql`
mutation createOrgAdminNote($orgId: ID!, $content: String!) {
  createOrgNote(orgId: $orgId, content: $content) {
    id
    content
    created
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdated
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
}
`;

type CreateNoteResponse = {
  createOrgNote: Note | null;
}

type CreateNoteInput = {
  content: string;
}

/* Edit Note Mutation */
const EDIT_NOTE = gql`
mutation editOrgAdminNote($id: ID!, $content: String!) {
  editOrgNote(id: $id, content: $content) {
    id
    content
    created
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdated
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
}
`;

type EditNoteInput = {
  id: string;
  content: string;
}

/* Delete Note Mutation */
const DELETE_NOTE = gql`
mutation deleteOrgNote($id: ID!) {
  success: deleteOrgNote(id: $id)
}
`;

type DeleteNoteResponse = {
  success: boolean;
}

/* Create Stripe Connect Account Mutation */
const CREATE_STRIPE_CONNECT_ACCOUNT = gql`
mutation createOrgStripeConnectAccount($orgId: ID!) {
  createOrgStripeConnectAccount(orgId: $orgId) {
    id
    stripeConnectAccount {
      stripeID
      hasCompletedOnboarding
      isUnderStripeReview
      hasMissingRequirements
      isDisabled
      hasChargesEnabled
      hasPayoutsEnabled
    }
  }
}
`;

/* Constants and Page Styles */
const states = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

const styles = StyleSheet.create({
  green: {
    color: Colors.primary500,
  },
  red: {
    color: Colors.error500,
  },
});

export default function OrgDetails() {
  const navigate = useNavigate();
  const { orgId } = useParams<{ orgId: string; }>();
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [changeLogsPage, setChangeLogsPage] = useState(0);
  const { data: orgData, loading: orgIsLoading, error: orgError } = useQuery<OrgDetailsQueryResponse>(GET_ORG_DETAILS, {
    variables: {
      id: orgId
    }
  });
  const { data: changeLogsData, loading: changeLogsAreLoading } = useQuery<ChangeLogsQueryResponse>(GET_CHANGE_LOGS, {
    variables: {
      id: orgId,
      page: changeLogsPage
    }
  });

  const [editName] = useMutation(EDIT_NAME);
  const [editAddress] = useMutation(EDIT_ADDRESS);
  const [changeJoinCode] = useMutation(CHANGE_JOIN_CODE);
  const [changePrimaryContact] = useMutation(CHANGE_PRIMARY_CONTACT);
  const [changeBillingContact] = useMutation(CHANGE_BILLING_CONTACT);
  const [cancelSubscription] = useMutation<CancelSubscriptionResponse>(CANCEL_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: GET_ORG_DETAILS,
        variables: {
          id: orgId
        }
      }
    ]
  });
  const [resumeSubscription] = useMutation<ResumeSubscriptionResponse>(RESUME_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: GET_ORG_DETAILS,
        variables: {
          id: orgId
        }
      }
    ]
  });
  const [createNote] = useMutation<CreateNoteResponse>(CREATE_NOTE, {
    update(cache, { data }) {
      if (!data?.createOrgNote || !orgData?.org) {
        return;
      }

      cache.modify({
        id: cache.identify(orgData.org),
        fields: {
          notes(existingNotes = []) {
            const newNoteRef = cache.writeFragment({
              data: data.createOrgNote,
              fragment: gql`
                fragment NewNote on Note {
                  id
                  content
                  created
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                  lastUpdated
                  lastUpdatedBy {
                    id
                    firstName
                    lastName
                  }
                }
              `
            });

            return [newNoteRef, ...existingNotes];
          },
        },
      });
    },
  });
  const [editNote] = useMutation(EDIT_NOTE);
  const [deleteNote] = useMutation<DeleteNoteResponse>(DELETE_NOTE, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.id || !orgData?.org) {
        return;
      }

      cache.modify({
        id: cache.identify(orgData.org),
        fields: {
          notes(existingNotes = [], { readField }) {
            return existingNotes.filter((noteRef: any) => readField('id', noteRef) !== variables.id);
          },
        },
      });
    },
  });
  const [createStripeConnectAccount] = useMutation(CREATE_STRIPE_CONNECT_ACCOUNT);

  /* Copy ID */
  const handleCopyOrgID = () => {
    navigator.clipboard.writeText(orgData?.org?.id || '');
    const id = generateId();
    const alert = <StandardAlert id={id} title='Org ID copied' type='success' />;
    addAlert(id, alert);
  }

  /* Edit Name */
  const handleEditName = async (values: EditNameInput) => {
    const { errors } = await editName({
      variables: {
        id: orgId,
        name: values.orgName,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to edit org name' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Org name changed' type='success' />;
      addAlert(id, alert);
    }
  }

  const editNameModal = (
    <FormModal<EditNameInput> title='Edit Org Name' initialValues={{ orgName: orgData?.org?.name || '' }} onSubmit={handleEditName}>
      <TextField label='Org Name' name='orgName' required />
    </FormModal>
  );

  /* Edit Address */
  const handleEditAddress = async (values: EditAddressInput) => {
    const { errors } = await editAddress({
      variables: {
        id: orgId,
        input: {
          street: values.streetAddress,
          city: values.city,
          state: values.state,
          zip: values.zipcode,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to edit org address' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Org address changed' type='success' />;
      addAlert(id, alert);
    }
  }

  const editAddressModal = (
    <FormModal<EditAddressInput> title='Edit Address' onSubmit={handleEditAddress} initialValues={{ streetAddress: orgData?.org?.address?.streetAddress || '', city: orgData?.org?.address?.city || '', state: orgData?.org?.address?.state || '', zipcode: orgData?.org?.address?.zipcode || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Street Address' name='streetAddress' required />
        <View style={{ flexDirection: 'row', gap: '16px' }}>
          <TextField label='City' name='city' required />
          <SingleSelect label='State' name='state' required>
            {states.map((state, index) => (
              <Choice label={state} value={state} key={index} />
            ))}
          </SingleSelect>
        </View>
        <TextField label='Zipcode' name='zipcode' required style={{ width: '50%' }} />
      </View>
    </FormModal>
  );

  /* Change Join Code */
  const handleChangeJoinCode = async () => {
    const { errors } = await changeJoinCode({
      variables: {
        id: orgId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to change join code' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Join code changed' type='success' />;
      addAlert(id, alert);
    }
  }

  const changeJoinCodeModal = (
    <ConfirmModal title='Change join code?' onConfirm={handleChangeJoinCode} confirmLabel='Change Code'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This org's join code will be permanently changed to a new random code.</StyledParagraph>
        <InfoPanel type='warning'>
          <StyledParagraph>You must have permission from an org owner/manager to do this.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  /* Change Primary Contact */
  const handleChangePrimaryContact = async (values: ChangeContactInput) => {
    const { errors } = await changePrimaryContact({
      variables: {
        orgId: orgId,
        userId: values.userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to change primary contact' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Primary contact changed' type='success' />;
      addAlert(id, alert);
    }
  }

  const changePrimaryContactModal = (
    <FormModal<ChangeContactInput> title='Change Primary Contact' onSubmit={handleChangePrimaryContact} submitLabel='Change Contact' initialValues={{ userId: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='BSID of new primary contact' name='userId' required />
        <InfoPanel type='info'>
          <StyledParagraph>The new primary contact must have the Account Owner org role in this organization.</StyledParagraph>
        </InfoPanel>
      </View>
    </FormModal>
  );

  /* Change Billing Contact */
  const handleChangeBillingContact = async (values: ChangeContactInput) => {
    const { errors } = await changeBillingContact({
      variables: {
        orgId: orgId,
        userId: values.userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to change billing contact' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Billing contact changed' type='success' />;
      addAlert(id, alert);
    }
  }

  const changeBillingContactModal = (
    <FormModal<ChangeContactInput> title='Change Billing Contact' onSubmit={handleChangeBillingContact} submitLabel='Change Contact' initialValues={{ userId: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='BSID of new billing contact' name='userId' required />
        <InfoPanel type='info'>
          <StyledParagraph>The new billing contact must have the Account Owner or Billing Manager org role in this organization.</StyledParagraph>
        </InfoPanel>
      </View>
    </FormModal>
  );

  /* Cancel Subscription */
  const handleCancelSubscription = async (values: CancelSubscriptionInput) => {
    const { errors } = await cancelSubscription({
      variables: {
        orgId: orgId,
        planId: values.planId,
        cancelReason: values.reason,
        feedback: values.feedback,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to cancel subscription' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Subscription cancelled' type='success' />;
      addAlert(id, alert);
    }
  }

  const cancelSubscriptionModal = (
    <FormModal<CancelSubscriptionInput> title='Cancel Subscription' onSubmit={handleCancelSubscription} submitLabel='Cancel Subscription' destructive initialValues={{ reason: '', feedback: '', planId: '' }}>
      <View style={{ gap: '16px' }}>
        <View>
          <StyledParagraph bold>What happens next?</StyledParagraph>
          <ul style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <li><StyledParagraph>The org's subscription will be cancelled at the end of their current billing period.</StyledParagraph></li>
            <li><StyledParagraph>The org's users will no longer have access to this product at the end of the billing period.</StyledParagraph></li>
            <li><StyledParagraph>The org's data for this product will be retained for 30 days. After 30 days, the data may be permanently deleted.</StyledParagraph></li>
          </ul>
        </View>

        <RadioGroup name='reason' label={'Select the org\'s reason for cancelling'} required>
          <Radio label={'It\'s too expensive'} value='TOO_EXPENSIVE' />
          <Radio label={'It\'s missing important features'} value='MISSING_FEATURES' />
          <Radio label={'I\'m switching to another service'} value='SWITCHED_SERVICE' />
          <Radio label={'I don\'t use it enough'} value='UNUSED' />
          <Radio label={'Poor customer service'} value='CUSTOMER_SERVICE' />
          <Radio label={'It\'s too complex or difficult to use'} value='TOO_COMPLEX' />
          <Radio label={'It\'s too low quality'} value='LOW_QUALITY' />
          <Radio label='Other' value='OTHER' />
        </RadioGroup>

        <TextArea label='Additional customer feedback' name='feedback' />
      </View>
    </FormModal>
  );

  /* Resume Subscription */
  const handleResumeSubscription = async (planId: string) => {
    const { errors } = await resumeSubscription({
      variables: {
        orgId: orgId,
        planId: planId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to resume subscription' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Subscription resumed' type='success' />;
      addAlert(id, alert);
    }
  }

  const resumeSubscriptionModal = (
    <ConfirmModal title='Resume Subscription?' onConfirm={handleResumeSubscription} confirmLabel='Resume'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This org's subscription will be resumed immediately, and will no longer be cancelled at the end of the billing period.</StyledParagraph>
        <StyledParagraph>The org will continue to incur recurring charges until they cancel their subscription again.</StyledParagraph>
        <InfoPanel type='warning'>
          <StyledParagraph>You must have permission from an org owner or billing manager to do this.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  /* Create Note */
  const handleCreateNote = async (values: CreateNoteInput) => {
    await createNote({
      variables: {
        orgId: orgId,
        content: values.content,
      },
    });
  }

  const createNoteModal = (
    <FormModal<CreateNoteInput> title='Create Note' submitLabel='Create' onSubmit={handleCreateNote} initialValues={{ content: '' }}>
      <TextEditor name='content' isEditMode buttonConfig={noteEditorConfig} />
    </FormModal>
  );

  /* Edit Note */
  const handleEditNote = async (values: EditNoteInput) => {
    await editNote({
      variables: {
        id: values.id,
        content: values.content,
      },
    });
  }

  const editNoteModal = (
    <FormModal<EditNoteInput> title='Edit Note' submitLabel='Save' onSubmit={handleEditNote} initialValues={{ id: '', content: '' }}>
      <TextEditor name='content' isEditMode buttonConfig={noteEditorConfig} />
    </FormModal>
  );

  /* Delete Note */
  const handleDeleteNote = async (id: string) => {
    await deleteNote({
      variables: {
        id: id,
      },
    });
  }

  const deleteNoteModal = (
    <ConfirmModal title='Delete note?' confirmLabel='Delete' onConfirm={handleDeleteNote} destructive>
      <StyledParagraph>This note will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Create Connect Account */
  const handleCreateConnectAccount = async () => {
    const { errors } = await createStripeConnectAccount({
      variables: {
        orgId: orgId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to create Stripe Connect account' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Stripe Connect account created' type='success' />;
      addAlert(id, alert);
    }
  }

  const createConnectAccountModal = (
    <ConfirmModal title='Enable Payment Processing' onConfirm={handleCreateConnectAccount} confirmLabel='Grant Access'>
      <View style={{ gap: '16px' }}>
        <InfoPanel type='warning'>
          <StyledParagraph>This feature is still limited. Access should only be granted to test orgs.</StyledParagraph>
        </InfoPanel>

        <StyledParagraph>This will create a Stripe Connect account for this org.</StyledParagraph>
        <StyledParagraph>After creation, the account owner will need to complete the onboarding process in the Org Admin application.</StyledParagraph>
        <StyledParagraph>Once onboarding is completed, the org will be able to accept payments from their customers.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (!state.user?.bsEmployeePermissions?.canLookupUsers) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (orgIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (orgError) {
    if (orgError.graphQLErrors[0].extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Orgs' to='/orgs' />
          <Breadcrumb label={orgData?.org?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>{orgData?.org?.name}</StyledHeading>
      </Cell>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Profile Info</StyledHeading>
              <View>
                <StyledCaption>ID</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{orgData?.org?.id}</StyledParagraph>
                  <Button label='Copy' variant='tertiary' role='button' action={handleCopyOrgID} leftIcon={Icons.Copy} iconSize='small' />
                </View>
              </View>
              <View>
                <StyledCaption>Business Name</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{orgData?.org?.name}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditOrganizationProfiles']}>
                    <ModalLauncher modal={editNameModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <View>
                <StyledCaption>Address</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{orgData?.org?.address.streetAddress}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditOrganizationProfiles']}>
                    <ModalLauncher modal={editAddressModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
                <StyledParagraph>{orgData?.org?.address.city}, {orgData?.org?.address.state} {orgData?.org?.address.zipcode}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Join Code</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{orgData?.org?.joinCode}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditOrganizationProfiles']}>
                    <ModalLauncher modal={changeJoinCodeModal}>
                      {({ openModal }) => (
                        <Button label='Reset' variant='tertiary' role='button' action={openModal} leftIcon={Icons.Redo} iconSize='small' style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <View>
                <StyledCaption>Created</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{new Date(orgData?.org?.created || '').toLocaleString()}</StyledParagraph>
                </View>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Users</StyledHeading>
              <View>
                <StyledCaption>Primary Contact</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <Link href={`/users/${orgData?.org?.primaryContact.id}`}>{orgData?.org?.primaryContact.firstName} {orgData?.org?.primaryContact.lastName}</Link>
                  <HasEmployeePermission permissions={['canEditOrganizationContacts']}>
                    <ModalLauncher modal={changePrimaryContactModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <View>
                <StyledCaption>Billing Contact</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <Link href={`/users/${orgData?.org?.billingContact.id}`}>{orgData?.org?.billingContact.firstName} {orgData?.org?.billingContact.lastName}</Link>
                  <HasEmployeePermission permissions={['canEditOrganizationContacts']}>
                    <ModalLauncher modal={changeBillingContactModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <Button label='View all users' variant='tertiary' role='link' href={`/orgs/${orgId}/users`} />
            </View>
          </Card>
        </Cell>
      </Row>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ gap: '16px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledHeading tag='h6'>Billing</StyledHeading>
                <HasEmployeePermission permissions={['canViewOrgBillingDetails', 'canEditOrgBillingDetails']}>
                  <Link href={`https://dashboard.stripe.com${(!window.location.href.includes('barscience.us') || window.location.href.includes('-dev') ? '/test' : '')}/customers/${orgData?.org?.stripeID}`}>View on Stripe</Link>
                </HasEmployeePermission>
              </View>

              <HasEmployeePermission permissions={['canViewOrgBillingDetails', 'canEditOrgBillingDetails']}>
                <ActionMenu label='More Info' alignment='right'>
                  <ActionItem label='Invoices' onClick={() => { navigate(`/orgs/${orgId}/invoices`); }} />
                  <ActionItem label='Payment Methods' onClick={() => { navigate(`/orgs/${orgId}/payment-methods`); }} />
                </ActionMenu>
              </HasEmployeePermission>
            </View>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Product</TableHeaderCell>
                  <TableHeaderCell>Plan</TableHeaderCell>
                  <TableHeaderCell>Start Date</TableHeaderCell>
                  <TableHeaderCell>Next Billing Date</TableHeaderCell>
                  <TableHeaderCell>Billable Users</TableHeaderCell>
                  <TableHeaderCell>Estimated Next Price</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <HasEmployeePermission permissions={['canEditCompanyProductAccess']}>
                    <TableHeaderCell></TableHeaderCell>
                  </HasEmployeePermission>
                </TableRow>
              </TableHeader>
              <TableBody>
                {orgData?.org?.plans.map((plan, index) => (
                  <TableRow key={index}>
                    <TableCell>{plan.plan.product.name}</TableCell>
                    <TableCell><Link href={`/plans/${plan.plan.id}`}>{plan.plan.name}</Link></TableCell>
                    <TableCell>{new Date(plan.startDate).toLocaleDateString()}</TableCell>
                    <TableCell>{plan.nextBillingDate ? new Date(plan.nextBillingDate).toLocaleDateString() : '----'}</TableCell>
                    <TableCell>{plan.countBillableUsers}</TableCell>
                    <TableCell>{plan.estimatedNextPrice ? plan.estimatedNextPrice : '----'}</TableCell>
                    <TableCell>{plan.cancelledAt ? <Tooltip content={<StyledParagraph>{getCanceledLabel(plan.cancelledAt, plan.cancelEffectiveDate)}</StyledParagraph>}><span className={css(Typography.paragraph2, styles.red)}>Cancelled</span></Tooltip> : <span className={css(Typography.paragraph2, styles.green)}>Active</span>}</TableCell>
                    <HasEmployeePermission permissions={['canEditCompanyProductAccess']}>
                      <TableCell>
                        <ModalLauncher modal={cancelSubscriptionModal}>
                          {({ openModal: openCancelModal }) => (
                            <ModalLauncher modal={resumeSubscriptionModal}>
                              {({ openModal: openResumeModal }) => (
                                <ModalLauncher modal={<ChangePlanModal orgId={orgId || ''} productId={plan.plan.product.id} currentPlanId={plan.plan.id} countBillableUsers={plan.countBillableUsers} />}>
                                  {({ openModal: openChangePlanModal }) => (
                                    <ActionMenu alignment='right'>
                                      {(!plan.cancelledAt) &&
                                        <ActionItem label='Change plan' onClick={openChangePlanModal} />
                                      }

                                      {(plan.cancelEffectiveDate !== null && new Date(plan.cancelEffectiveDate) > new Date()) &&
                                        <ActionItem label='Resume subscription' onClick={() => { openResumeModal(plan.plan.id); }} />
                                      }

                                      {(!plan.cancelledAt && plan.plan.product.id !== 'org') &&
                                        <ActionItem label='End subscription' onClick={() => { openCancelModal({ reason: '', feedback: '', planId: plan.plan.id }); }} />
                                      }
                                    </ActionMenu>
                                  )}
                                </ModalLauncher>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </HasEmployeePermission>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </View>
        </Card>
      </Cell>
      <FlagCheck flagId='stripe_connect_onboarding'>
        <Cell lg={12} md={8} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledHeading tag='h6'>Payment Processing</StyledHeading>
                  {orgData?.org?.stripeConnectAccount &&
                    <HasEmployeePermission permissions={['canViewOrgBillingDetails', 'canEditOrgBillingDetails']}>
                      <Link href={`https://dashboard.stripe.com${(!window.location.href.includes('barscience.us') || window.location.href.includes('-dev') ? '/test' : '')}/connect/accounts/${orgData?.org?.stripeConnectAccount.stripeID}`}>View on Stripe</Link>
                    </HasEmployeePermission>
                  }
                </View>
              </View>

              {orgData?.org?.stripeConnectAccount ?
                <View style={{ gap: '16px' }}>
                  <View style={{ flexDirection: 'row', gap: '32px' }}>
                    <View>
                      <StyledCaption>Payments</StyledCaption>
                      {orgData.org.stripeConnectAccount.hasChargesEnabled ?
                        <StyledParagraph style={{ color: Colors.primary500, fontWeight: 600 }}>Enabled</StyledParagraph>
                        :
                        <StyledParagraph style={{ color: Colors.error500, fontWeight: 600 }}>Disabled</StyledParagraph>
                      }
                    </View>
                    <View>
                      <StyledCaption>Payouts</StyledCaption>
                      {orgData.org.stripeConnectAccount.hasPayoutsEnabled ?
                        <StyledParagraph style={{ color: Colors.primary500, fontWeight: 600 }}>Enabled</StyledParagraph>
                        :
                        <StyledParagraph style={{ color: Colors.error500, fontWeight: 600 }}>Disabled</StyledParagraph>
                      }
                    </View>
                  </View>

                  <View>
                    <StyledCaption>Onboarding Status</StyledCaption>
                    {orgData.org.stripeConnectAccount.hasCompletedOnboarding ?
                      (orgData.org.stripeConnectAccount.isUnderStripeReview ?
                        <StyledParagraph>Under Stripe Review</StyledParagraph>
                        :
                        <StyledParagraph>Completed</StyledParagraph>
                      )
                      :
                      <StyledParagraph>Incomplete</StyledParagraph>
                    }
                  </View>

                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px'}}>
                    {orgData.org.stripeConnectAccount.hasMissingRequirements &&
                      <InfoPanel type='warning' style={{ padding: '8px', width: 'fit-content' }}>
                        <StyledParagraph bold style={{ color: Colors.warning500, fontSize: '14px' }}>Has missing requirements</StyledParagraph>
                      </InfoPanel>
                    }

                    {orgData.org.stripeConnectAccount.isDisabled &&
                      <InfoPanel type='error' style={{ padding: '8px', width: 'fit-content' }}>
                        <StyledParagraph bold style={{ color: Colors.error500, fontSize: '14px' }}>Disabled on Stripe</StyledParagraph>
                      </InfoPanel>
                    }
                  </View>
                </View>
                :
                <View style={{ alignItems: 'center', gap: '16px' }}>
                  <StyledParagraph>This org does not have access to payment processing.</StyledParagraph>
                  <ModalLauncher modal={createConnectAccountModal}>
                    {({ openModal }) => (
                      <Button label='Start Onboarding' variant='secondary' role='button' action={openModal} />
                    )}
                  </ModalLauncher>
                </View>
              }
            </View>
          </Card>
        </Cell>
      </FlagCheck>
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            <View>
              <View style={{ gap: '16px' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h6'>Notes</StyledHeading>
                  <HasEmployeePermission permissions={['canEditOrganizationProfiles']}>
                    <ModalLauncher modal={createNoteModal}>
                      {({ openModal }) => (
                        <Button label='Create Note' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
                {orgData?.org?.notes.map((note) => (
                  <View key={note.id} style={{ alignItems: 'flex-start', borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '16px', gap: '8px' }}>
                    <View style={{ gap: '8px', width: '100%' }}>
                      <TextEditor key={note.id} name={'user-note-' + note.id} isEditMode={false} value={note.content} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />
                      <StyledCaption>Created {new Date(note.created).toLocaleString()} by {note.createdBy.firstName} {note.createdBy.lastName} {note.lastUpdated ? (`(Last updated ${new Date(note.lastUpdated).toLocaleString()} by ${note.lastUpdatedBy?.firstName} ${note.lastUpdatedBy?.lastName})`) : ''}</StyledCaption>
                    </View>
                    <HasEmployeePermission permissions={['canEditOrganizationProfiles']}>
                      <ModalLauncher modal={deleteNoteModal}>
                        {({ openModal: openDeleteModal }) => (
                          <ModalLauncher modal={editNoteModal}>
                            {({ openModal: openEditModal }) => (
                              <ActionMenu alignment='right'>
                                <ActionItem label='Edit' onClick={() => { openEditModal({ id: note.id, content: note.content }); }} />
                                <ActionItem label='Delete' onClick={() => { openDeleteModal(note.id); }} />
                              </ActionMenu>
                            )}
                          </ModalLauncher>
                        )}
                      </ModalLauncher>
                    </HasEmployeePermission>
                  </View>
                ))}
                {orgData?.org?.notes.length === 0 && <StyledParagraph>There are no notes for this org.</StyledParagraph>}
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            {changeLogsAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View style={{ gap: '16px', maxWidth: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h6'>Change Logs</StyledHeading>
                  <PageButtons currentPage={changeLogsPage} numPages={changeLogsData?.org?.changeLogs.length === CHANGE_LOGS_PER_PAGE ? changeLogsPage + 2 : changeLogsPage} onPageChange={setChangeLogsPage} />
                </View>
                <View style={{ gap: '8px', maxWidth: '100%' }}>
                  {changeLogsData?.org?.changeLogs.map((log, index) => (
                    <View key={index} style={{ borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', gap: '8px', maxWidth: '100%', paddingBottom: '16px' }}>
                      <StyledParagraph style={{ flexGrow: 1 }}>{log.description}</StyledParagraph>
                      <StyledParagraph style={{ color: Colors.neutral700, flexGrow: 0, fontSize: '14px', maxWidth: '150px', minWidth: '150px', width: '150px', wordWrap: 'break-word' }}><TimeAgo date={log.timestamp} title={log.timestamp} /></StyledParagraph>
                    </View>
                  ))}
                </View>
                {changeLogsData?.org?.changeLogs.length === 0 && <StyledParagraph>There are no change logs for this organization.</StyledParagraph>}
              </View>
            }
          </Card>
        </Cell>
      </Row>
    </StandardGrid>
  );
}

function getCanceledLabel(cancelledAt: string | null, cancelledEffective: string | null) {
  const cancelledDate = new Date(cancelledAt || '');
  const endingDate = new Date(cancelledEffective || '');
  const today = new Date();

  if (cancelledAt !== null && endingDate > today) {
    return `Cancelled ${cancelledDate.toLocaleDateString()}, ending ${endingDate.toLocaleDateString()}`;
  } else if (cancelledAt !== null) {
    return `Cancelled ${cancelledDate.toLocaleDateString()}, ended ${endingDate.toLocaleDateString()}`;
  }

  return 'Active';
}

type ChangePlanModalProps = {
  orgId: string;
  productId: string;
  currentPlanId: string;
  countBillableUsers: number;
  handleClose?: () => void;
}

function ChangePlanModal(props: ChangePlanModalProps) {
  const { addAlert } = useAlertState();
  const [selectedPlanID, setSelectedPlanID] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const { data: plansData, loading: plansAreLoading } = useQuery<ProductPlansQueryResponse>(GET_PRODUCT_PLANS, {
    variables: {
      productId: props.productId,
    }
  });
  const [previewPlanChange, { data: previewData, loading: previewIsLoading, error: previewError }] = useLazyQuery<PreviewPlanChangeResponse>(PREVIEW_PLAN_CHANGE);
  const [changeSubscriptionPlan, { loading: changePlanIsLoading }] = useMutation(CHANGE_PLAN, {
    refetchQueries: [
      {
        query: GET_ORG_DETAILS,
        variables: {
          id: props.orgId,
        }
      }
    ]
  });

  useEffect(() => {
    if (selectedPlanID !== null) {
      previewPlanChange({
        variables: {
          orgId: props.orgId,
          newPlanId: selectedPlanID,
        }
      });
    }
  }, [selectedPlanID, previewPlanChange, props.orgId]);

  const publicPlans = plansData?.plans.filter(plan => !plan.isInactive && plan.isPublic && plan.id !== props.currentPlanId) || [];
  const otherPlans = plansData?.plans.filter(plan => !plan.isInactive && !plan.isPublic && plan.id !== props.currentPlanId) || [];
  const selectedPlan = plansData?.plans.find(plan => plan.id === selectedPlanID);

  const handleChangePlan = async () => {
    const { errors } = await changeSubscriptionPlan({
      variables: {
        orgId: props.orgId,
        newPlanId: selectedPlanID,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Failed to change plan' description={errors[0].message} type='error' />;
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Plan changed' type='success' />;
      addAlert(id, alert);
    }

    props.handleClose && props.handleClose();
  }

  const selectPlanBody = (
    <ModalBody>
      {plansAreLoading ?
        <View style={{ alignItems: 'center' }}>
          <CircularSpinner size='medium' />
        </View>
        :
        <View style={{ gap: '80px' }}>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h6'>Public Plans</StyledHeading>
            {publicPlans.map((plan) => (
              <View key={plan.id} style={{ border: `1px solid ${Colors.neutral300}`, borderRadius: '4px', cursor: 'pointer', padding: '16px', ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 } }} onClick={() => { setSelectedPlanID(plan.id); }}>
                <StyledParagraph bold style={{ fontSize: '18px' }}>{plan.name}</StyledParagraph>
                {plan.description && <StyledParagraph style={{ color: Colors.neutral700 }}>{plan.description}</StyledParagraph>}
                <StyledParagraph>{plan.cost}{plan.costType === 'PER_USER' && ' / user'}{plan.type === 'MONTHLY' ? ' / month' : ' / year'}</StyledParagraph>
                <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>{plan.userLimit ? `Up to ${plan.userLimit} users` : 'Unlimted Users'}</StyledParagraph>
              </View>
            ))}

            {publicPlans.length === 0 && <StyledParagraph>There are no public plans for this product.</StyledParagraph>}
          </View>
          {otherPlans.length > 0 &&
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Other Plans</StyledHeading>
              {otherPlans.map((plan) => (
                <View key={plan.id} style={{ border: `1px solid ${Colors.neutral300}`, borderRadius: '4px', cursor: 'pointer', padding: '16px', ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 } }} onClick={() => { setSelectedPlanID(plan.id); }}>
                  <StyledParagraph bold style={{ fontSize: '18px' }}>{plan.name}</StyledParagraph>
                  {plan.description && <StyledParagraph style={{ color: Colors.neutral700 }}>{plan.description}</StyledParagraph>}
                  <StyledParagraph>{plan.cost}{plan.costType === 'PER_USER' && ' / user'}{plan.type === 'MONTHLY' ? ' / month' : ' / year'}</StyledParagraph>
                  <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>{plan.userLimit ? `Up to ${plan.userLimit} users` : 'Unlimted Users'}</StyledParagraph>
                </View>
              ))}
            </View>
          }
        </View>
      }
    </ModalBody>
  );

  const previewBody = (
    <ModalBody>
      {previewIsLoading ?
        <View style={{ alignItems: 'center' }}>
          <CircularSpinner size='medium' />
        </View>
        :
        <View style={{ gap: '16px' }}>
          <View>
            <StyledCaption>New Plan</StyledCaption>
            <StyledParagraph>{selectedPlan?.name}</StyledParagraph>
          </View>
          <View>
            <StyledCaption>Next Invoice Amount (including prorations)</StyledCaption>
            <StyledParagraph>{previewData?.previewSubscriptionChange.nextInvoiceAmount}</StyledParagraph>
          </View>
          <View>
            <StyledCaption>Next Invoice Date</StyledCaption>
            <StyledParagraph>{new Date(previewData?.previewSubscriptionChange.nextInvoiceDate || '').toLocaleDateString()}</StyledParagraph>
          </View>
          <View>
            <StyledCaption>Recurring Invoice Amount (based on current users)</StyledCaption>
            {selectedPlan && <StyledParagraph>{selectedPlan.costType === 'PER_USER' ? `${currency(selectedPlan.cost).multiply(props.countBillableUsers).format()} ${selectedPlan.type === 'MONTHLY' ? '/ month' : '/ year'}` : `${selectedPlan.cost} ${selectedPlan.type === 'MONTHLY' ? '/ month' : '/ year'}`}</StyledParagraph>}
          </View>
          <Checkbox label='I confirm I have permission from an org owner or billing admin to make this change' name='isConfirmed' checked={isConfirmed} onChange={(_, value) => { setIsConfirmed(value); }} />
        </View>
      }
      {previewError && <StyledParagraph>Error: {previewError.message}</StyledParagraph>}
    </ModalBody>
  );

  return (
    <Modal
      header={<ModalHeader title={selectedPlanID === null ? 'Select a new plan' : 'Preview Changes'} />}
      body={
        <>
          {selectedPlanID === null && selectPlanBody}
          {selectedPlanID !== null && previewBody}
        </>
      }
      footer={
        <ModalFooter>
          {selectedPlanID !== null &&
            <View style={{ flexDirection: 'row', gap: '16px' }}>
              <Button label='Cancel' variant='tertiary' role='button' action={() => { setSelectedPlanID(null); }} />
              <Button label='Change Plan' variant='primary' role='button' action={handleChangePlan} loading={changePlanIsLoading} disabled={!isConfirmed} />
            </View>
          }
        </ModalFooter>
      }
      handleClose={props.handleClose}
    />
  );
}