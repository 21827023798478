import { useState } from "react";
import { FeatureFlagRule } from "./FeatureFlagDetails";
import { Button, Colors, Form, FormModal, Icon, Icons, InfoPanel, ModalLauncher, StyledParagraph, TextField, View, useForm } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";
import { gql, useMutation } from "@apollo/client";

/* Delete Rule Mutation */
const DELETE_RULE = gql`
mutation deleteFeatureFlagRule($ruleId: ID!) {
  success: deleteFeatureFlagRule(ruleId: $ruleId)
}
`;

type DeleteRuleResponse = {
  success: boolean;
}

type FeatureFlagRuleProps = {
  rule: FeatureFlagRule;
}

/* Add User Mutation */
const ADD_USER = gql`
mutation addUserToFeatureFlagRule($ruleId: ID!, $userId: ID!) {
  success: addUserToFeatureFlagRule(ruleId: $ruleId, userId: $userId)
}
`;

type AddUserResponse = {
  success: boolean;
}

type AddUserInput = {
  userId: string;
}

/* Remove User Mutation */
const REMOVE_USER = gql`
mutation RemoveUserFromFeatureFlagRule($ruleId: ID!, $userId: ID!) {
  success: removeUserFromFeatureFlagRule(ruleId: $ruleId, userId: $userId)
}
`;

type RemoveUserResponse = {
  success: boolean;
}

/* Add Org Mutation */
const ADD_ORG = gql`
mutation addOrgToFeatureFlagRule($ruleId: ID!, $orgId: ID!) {
  success: addOrgToFeatureFlagRule(ruleId: $ruleId, orgId: $orgId)
}
`;

type AddOrgResponse = {
  success: boolean;
}

type AddOrgInput = {
  orgId: string;
}

/* Remove Org Mutation */
const REMOVE_ORG = gql`
mutation removeOrgFromFeatureFlagRule($ruleId: ID!, $orgId: ID!) {
  success: removeOrgFromFeatureFlagRule(ruleId: $ruleId, orgId: $orgId)
}
`;

type RemoveOrgResponse = {
  success: boolean;
}

/* Edit Percentage Rollout Mutation */
const EDIT_PERCENTAGE_ROLLOUT = gql`
mutation editFeatureFlagRulePercentage($ruleId: ID!, $percent: Int!) {
  success: editFeatureFlagRulePercentage(ruleId: $ruleId, percent: $percent)
}
`;

type EditPercentageRolloutResponse = {
  success: boolean;
}

const styles = StyleSheet.create({
  ruleLabelShared: {
    fontWeight: 'bold',
    'user-select': 'none',
  },
  ruleLabelOn: {
    color: Colors.primary500,
  },
  ruleLabelOff: {
    color: Colors.error500,
  }
});

export default function FeatureFlagRuleCard(props: FeatureFlagRuleProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteRule] = useMutation<DeleteRuleResponse>(DELETE_RULE, {
    update(cache, { data }) {
      cache.evict({
        id: cache.identify(props.rule),
      });
    },
  });
  const [addUser] = useMutation<AddUserResponse>(ADD_USER, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.userId) {
        return;
      }

      cache.modify({
        id: cache.identify(props.rule),
        fields: {
          users(existingUsers = []) {
            return [...existingUsers, variables.userId];
          },
        },
      });
    },
  });
  const [removeUser] = useMutation<RemoveUserResponse>(REMOVE_USER, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.userId) {
        return;
      }

      cache.modify({
        id: cache.identify(props.rule),
        fields: {
          users(existingUsers = []) {
            return existingUsers.filter((userId: string) => userId !== variables.userId);
          },
        },
      });
    },
  });
  const [editPercentageRollout] = useMutation<EditPercentageRolloutResponse>(EDIT_PERCENTAGE_ROLLOUT, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(props.rule),
        fields: {
          percent() {
            return variables.percent;
          },
        },
      });
    }
  });
  const [addOrg] = useMutation<AddOrgResponse>(ADD_ORG, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.orgId) {
        return;
      }

      cache.modify({
        id: cache.identify(props.rule),
        fields: {
          orgs(existingOrgs = []) {
            return [...existingOrgs, variables.orgId];
          },
        },
      });
    }
  });
  const [removeOrg] = useMutation<RemoveOrgResponse>(REMOVE_ORG, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.orgId) {
        return;
      }

      cache.modify({
        id: cache.identify(props.rule),
        fields: {
          orgs(existingOrgs = []) {
            return existingOrgs.filter((orgId: string) => orgId !== variables.orgId);
          },
        },
      });
    }
  });


  const handleAddUser = async (values: { userId: string }) => {
    await addUser({
      variables: {
        ruleId: props.rule.id,
        userId: values.userId,
      },
    });

    addUserForm.resetValues();
  }

  const handleRemoveUser = async (userId: string) => {
    await removeUser({
      variables: {
        ruleId: props.rule.id,
        userId: userId,
      },
    });
  }

  const handleAddOrg = async (values: { orgId: string }) => {
    await addOrg({
      variables: {
        ruleId: props.rule.id,
        orgId: values.orgId,
      },
    });

    addOrgForm.resetValues();
  }

  const handleRemoveOrg = async (orgId: string) => {
    await removeOrg({
      variables: {
        ruleId: props.rule.id,
        orgId: orgId,
      },
    });
  }

  const handleDeleteRule = async (values: { confirmation: string }) => {
    await deleteRule({
      variables: {
        ruleId: props.rule.id,
      },
    });
  }

  const handleEditPercentageRollout = async (values: { percentage: string }) => {
    await editPercentageRollout({
      variables: {
        ruleId: props.rule.id,
        percent: Math.floor(parseFloat(values.percentage)),
      },
    });
  }

  const addUserForm = useForm<AddUserInput>({
    initialValues: {
      userId: '',
    },
    onSubmit: handleAddUser,
  });

  const addOrgForm = useForm<AddOrgInput>({
    initialValues: {
      orgId: '',
    },
    onSubmit: handleAddOrg,
  });

  const confirmDeleteRuleModal = (
    <FormModal<{ confirmation: string }> title='Delete rule?' initialValues={{ confirmation: '' }} submitLabel='Delete' destructive onSubmit={handleDeleteRule}>
      <View style={{ gap: '16px' }}>
        <InfoPanel type='warning'>
          <View style={{ gap: '16px' }}>
            <StyledParagraph><span style={{ fontWeight: 'bold' }}>WARNING:</span> This rule will be <span style={{ fontWeight: 'bold' }}>permanently deleted</span>.</StyledParagraph>
            <StyledParagraph style={{ fontStyle: 'italic' }}>Depnding on the rule configuration, this may impact a large number of users.</StyledParagraph>
          </View>
        </InfoPanel>
        <TextField label='Type "I am completely sure" to proceed' name='confirmation' validate={(_, value) => {
          if (value.toLowerCase() === 'i am completely sure') {
            return null;
          }

          return 'Phrases do not match';
        }} required />
      </View>
    </FormModal>
  );

  const editPercentRolloutModal = (
    <FormModal<{ percentage: string }> title='Edit Percentage Rollout' onSubmit={handleEditPercentageRollout} initialValues={{ percentage: props.rule.__typename === 'FeatureFlagRulePercentOfOrgs' ? props.rule.percent.toString() : '' }}>
      <View>
        <TextField label='Percentage' name='percentage' type='number' required />
      </View>
    </FormModal>
  );

  return (
    <>
      <View style={{ border: `1px solid ${Colors.neutral200}`, borderRadius: '4px' }}>
        <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '4px', padding: '12px', ...(isOpen ? { borderBottom: `1px solid ${Colors.neutral200}` } : {}) }} onClick={() => { setIsOpen(!isOpen); }}>
          {isOpen && <Icon size='small' icon={Icons.ChevronDown} />}
          {!isOpen && <Icon size='small' icon={Icons.ChevronRight} />}

          <StyledParagraph style={{ display: 'flex', gap: '4px', 'user-select': 'none' }}>
            {props.rule.action === 'ON' ?
              <span className={css(styles.ruleLabelShared, styles.ruleLabelOn)}>On</span>
              :
              <span className={css(styles.ruleLabelShared, styles.ruleLabelOff)}>Off</span>
            }
            {getOnOffLabel(props.rule)}
          </StyledParagraph>
        </View>
        {isOpen &&
          <View style={{ backgroundColor: Colors.neutral50, gap: '24px', padding: '12px' }}>
            {props.rule.__typename === 'FeatureFlagRuleSpecificUsers' &&
              <>
                <View>
                  {props.rule.users.map((user, index) => {
                    return (
                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={index}>
                        <StyledParagraph>{user}</StyledParagraph>
                        <Button label='Remove' variant='tertiary' role='button' action={async () => { await handleRemoveUser(user); }} destructive />
                      </View>
                    );
                  })}
                </View>
                <Form handleSubmit={addUserForm.handleSubmit}>
                  <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                    <TextField label='User BSID' name='userId' value={addUserForm.values.userId} error={addUserForm.errors.userId} onChange={addUserForm.handleChange} onValidate={addUserForm.handleValidate} required style={{ maxWidth: '450px' }} validate={(_, value) => {
                      if (props.rule.__typename === 'FeatureFlagRuleSpecificUsers' && props.rule.users.includes(value)) {
                        return 'User already added';
                      }

                      return null;
                    }} />
                    <Button label='Add User' variant='tertiary' role='button' action={addUserForm.handleSubmit} style={{ ...(addUserForm.errors.userId ? { marginBottom: '28px' } : {}) }} loading={addUserForm.isLoading} disabled={addUserForm.hasError} />
                  </View>
                </Form>
              </>
            }

            {props.rule.__typename === 'FeatureFlagRuleSpecificOrgs' &&
              <>
                <View>
                  {props.rule.orgs.map((org, index) => {
                    return (
                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={index}>
                        <StyledParagraph>{org}</StyledParagraph>
                        <Button label='Remove' variant='tertiary' role='button' action={async () => { await handleRemoveOrg(org); }} destructive />
                      </View>
                    );
                  })}
                </View>
                <Form handleSubmit={addOrgForm.handleSubmit}>
                  <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                    <TextField label='Org ID' name='orgId' value={addOrgForm.values.orgId} error={addOrgForm.errors.orgId} onChange={addOrgForm.handleChange} onValidate={addOrgForm.handleValidate} required style={{ maxWidth: '450px' }} validate={(_, value) => {
                      if (props.rule.__typename === 'FeatureFlagRuleSpecificOrgs' && props.rule.orgs.includes(value)) {
                        return 'Org already added';
                      }

                      return null;
                    }} />
                    <Button label='Add Org' variant='tertiary' role='button' action={addOrgForm.handleSubmit} style={{ ...(addOrgForm.errors.orgId ? { marginBottom: '28px' } : {}) }} loading={addOrgForm.isLoading} disabled={addOrgForm.hasError} />
                  </View>
                </Form>
              </>
            }

            {props.rule.__typename === 'FeatureFlagRulePercentOfOrgs' &&
              <>
                <View>
                  <StyledParagraph bold>Percentage</StyledParagraph>
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                    <StyledParagraph>{props.rule.percent}%</StyledParagraph>
                    <ModalLauncher modal={editPercentRolloutModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} />
                      )}
                    </ModalLauncher>
                  </View>
                </View>
              </>
            }

            <ModalLauncher modal={confirmDeleteRuleModal}>
              {({ openModal }) => (
                <Button label='Delete Rule' variant='primary' role='button' action={openModal} destructive />
              )}
            </ModalLauncher>
          </View>
        }
      </View>
    </>
  );
}

const getOnOffLabel = (rule: FeatureFlagRule) => {
  switch (rule.__typename) {
    case 'FeatureFlagRuleSpecificUsers':
      return `for ${rule.users.length} specific user${rule.users.length !== 1 ? 's' : ''}`;
    case 'FeatureFlagRuleSpecificOrgs':
      return `for ${rule.orgs.length} specific org${rule.orgs.length !== 1 ? 's' : ''}`;
    case 'FeatureFlagRuleBSEmployees':
      return `for Bar Science employees`;
    case 'FeatureFlagRulePercentOfOrgs':
      return `for ${rule.percent}% of all orgs`;
    default:
      return '';
  }
}