import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Checkbox, Colors, ConfirmModal, ErrorPage, FormModal, HasEmployeePermission, InfoPanel, Link, ModalLauncher, NotFound, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useParams } from "react-router-dom";

/* Get Plan Query */
const GET_PLAN_DETAILS = gql`
query getProductPlanDetails($id: ID!) {
  plan(id: $id) {
    id
    name
    description
    product {
      id
      name
    }
    type
    cost
    costType
    isInactive
    isPublic
    userLimit
    stripeId
  }
}
`;

type GetPlanDetailsResponse = {
  plan: ProductPlan | null;
}

type ProductPlan = {
  id: string;
  name: string;
  description: string | null;
  product: {
    id: string;
    name: string;
  };
  type: 'MONTHLY' | 'ANNUAL';
  cost: number;
  costType: 'FLAT' | 'PER_USER';
  isInactive: boolean;
  isPublic: boolean;
  userLimit: number | null;
  stripeId: string | null;
}

/* Edit Plan Mutation */
const EDIT_PLAN = gql`
mutation editProductPlan($id: ID!, $input: EditProductPlanInput!) {
  editProductPlan(id: $id, input: $input) {
    id
    name
    description
    isPublic
    userLimit
    stripeId
  }
}
`;

type EditProductPlanInput = {
  name: string;
  description: string;
  isPublic: boolean;
  userLimit: string;
  stripeId: string;
}

/* Set Archive Status Mutation */
const SET_ARCHIVE_STATUS = gql`
mutation setProductPlanArchiveStatus($id: ID!, $isArchived: Boolean!) {
  archiveProductPlan(id: $id, isArchived: $isArchived) {
    id
    isInactive
  }
}
`;

export default function PlanDetails() {
  const { id } = useParams<{ id: string }>();
  const { addAlert } = useAlertState();
  const { data: planData, loading: planIsLoading, error: planError } = useQuery<GetPlanDetailsResponse>(GET_PLAN_DETAILS, {
    variables: {
      id: id,
    },
  });
  const [editPlan] = useMutation(EDIT_PLAN);
  const [setArchiveStatus] = useMutation(SET_ARCHIVE_STATUS);

  const handleEditPlan = async (values: EditProductPlanInput) => {
    let userLimit: number | null = parseInt(values.userLimit || '0');
    if (isNaN(userLimit)) {
      userLimit = null;
    } else if (userLimit <= 0) {
      userLimit = null;
    }

    const { errors } = await editPlan({
      variables: {
        id: id,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          isPublic: values.isPublic,
          userLimit: userLimit,
          stripeId: values.stripeId ? values.stripeId : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to edit plan' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const editPlanModal = (
    <FormModal<EditProductPlanInput> title='Edit Plan' onSubmit={handleEditPlan} initialValues={{ name: planData?.plan?.name || '', description: planData?.plan?.description || '', isPublic: planData?.plan?.isPublic || false, userLimit: planData?.plan?.userLimit?.toString() || '', stripeId: planData?.plan?.stripeId || '' }}>
      <View style={{ gap: '16px' }}>
        <InfoPanel type='warning'>
          <StyledParagraph>Changes must be made to Stripe separately.</StyledParagraph>
        </InfoPanel>

        <TextField label='Plan Name' name='name' required />

        <TextArea label='Description' name='description' />

        <TextField label='User Limit' name='userLimit' type='number' />

        <TextField label='Stripe ID' name='stripeId' />

        <Checkbox label='Is Public?' description='This plan will be visible to all users and displayed on the pricing pages.' name='isPublic' />
      </View>
    </FormModal>
  );

  const handleArchivePlan = async () => {
    const { errors } = await setArchiveStatus({
      variables: {
        id: id,
        isArchived: true,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to archive plan' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const archivePlanModal = (
    <ConfirmModal title='Archive plan?' confirmLabel='Archive' destructive onConfirm={handleArchivePlan}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This plan will be archived. No organization will be able to create a new subscription with this plan.</StyledParagraph>
        <InfoPanel type='warning'>
          <StyledParagraph>You will need to archive this plan on Stripe manually.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  const handleRestorePlan = async () => {
    const { errors } = await setArchiveStatus({
      variables: {
        id: id,
        isArchived: false,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to restore plan' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const restorePlanModal = (
    <ConfirmModal title='Restore plan?' confirmLabel='Restore' onConfirm={handleRestorePlan}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This plan will be restored. Organizations will be able to create new subscriptions with this plan.</StyledParagraph>
        <InfoPanel type='warning'>
          <StyledParagraph>If this plan is billable, make sure this plan is unarchived on Stripe first.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  if (planIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (planError) {
    if (planError.graphQLErrors[0].extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Products' to='/products' />
          <Breadcrumb label={planData?.plan?.product.name || ''} to={`/products/${planData?.plan?.product.id}`} />
          <Breadcrumb label={planData?.plan?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ gap: '4px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
              <StyledHeading tag='h3'>{planData?.plan?.name}</StyledHeading>
              {planData?.plan?.isInactive ? <StyledParagraph bold style={{ backgroundColor: Colors.error500, borderRadius: '4px', color: Colors.shades0, fontSize: '12px', padding: '4px 8px' }}>Archived</StyledParagraph> : null}
            </View>
            <StyledParagraph>{planData?.plan?.description}</StyledParagraph>
          </View>

          <HasEmployeePermission permissions={['canEditProductPlans', 'canDeleteProductPlans']}>
            <ModalLauncher modal={editPlanModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={archivePlanModal}>
                  {({ openModal: openArchiveModal }) => (
                    <ModalLauncher modal={restorePlanModal}>
                      {({ openModal: openRestoreModal }) => (
                        <ActionMenu alignment='right'>
                          <ActionItem label='Edit Plan' onClick={openEditModal} />
                          <HasEmployeePermission permissions={['canEditProductPlans', 'canDeleteProductPlans']}>
                            {planData?.plan?.isInactive ?
                              <ActionItem label='Restore Plan' onClick={openRestoreModal} />
                              :
                              <ActionItem label='Archive Plan' onClick={openArchiveModal} />
                            }
                          </HasEmployeePermission>
                        </ActionMenu>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasEmployeePermission>
        </View>
      </Cell>
      <Cell lg={6} md={6} sm={4}>
        <Card size='medium'>
          <View style={{ gap: '16px' }}>
            <View>
              <StyledCaption>ID</StyledCaption>
              <StyledParagraph>{planData?.plan?.id}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Renewal Type</StyledCaption>
              <StyledParagraph>{planData?.plan?.type}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Cost</StyledCaption>
              <StyledParagraph>{`${planData?.plan?.cost}${planData?.plan?.costType === 'PER_USER' ? ' per user' : ''}`}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>User Limit</StyledCaption>
              <StyledParagraph>{planData?.plan?.userLimit || '----'}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Stripe ID</StyledCaption>
              <StyledParagraph>{planData?.plan?.stripeId ? <Link href={`https://dashboard.stripe.com${(!window.location.href.includes('barscience.us') || window.location.href.includes('-dev') ? '/test' : '')}/prices/${planData.plan.stripeId}`}>{planData.plan.stripeId}</Link> : '----'}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Public</StyledCaption>
              {planData?.plan?.isPublic ? <StyledParagraph style={{ color: Colors.primary500, fontWeight: 600 }}>YES</StyledParagraph> : <StyledParagraph style={{ color: Colors.error500, fontWeight: 600 }}>NO</StyledParagraph>}
            </View>
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}