import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, FormModal, HasEmployeePermission, InfoPanel, Link, ModalLauncher, NotFound, SingleSelect, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";
import { IsValidSlug } from "../../util/validators";

/* Get Product Query */
const GET_PRODUCT = gql`
query getProductDetails($id: ID!) {
  product(id: $id) {
    id
    name
    description
    isPublic
    roles {
      id
      name
      description
      sortOrder
      isBillable
    }
    stripeId
  }
}
`;

type GetProductDetailsResponse = {
  product: Product | null;
}

type Product = {
  id: string;
  name: string;
  description: string | null;
  isPublic: boolean;
  roles: Role[];
  stripeId: string | null;
}

type Role = {
  id: string;
  name: string;
  description: string | null;
  sortOrder: number;
  isBillable: boolean;
}

/* Get Product Plans */
const GET_PRODUCT_PLANS = gql`
query getPlansForProduct($productId: ID!) {
  plans(productId: $productId) {
    id
    name
    description
    type
  }
}
`;

type GetProductPlansResponse = {
  plans: Plan[];
}

type Plan = {
  id: string;
  name: string;
  description: string | null;
  type: 'MONTHLY' | 'ANNUAL';
}

/* Edit Product Mutation */
const EDIT_PRODUCT = gql`
mutation editProduct($id: ID!, $input: EditProductInput!) {
  editProduct(id: $id, input: $input) {
    id
    name
    description
    isPublic
    stripeId
  }
}
`;

type EditProductResponse = {
  editProduct: {
    id: number;
    name: string;
    description: string | null;
    isPublic: boolean;
    stripeId: string | null;
  } | null;
}

type EditProductInput = {
  name: string;
  description: string;
  isPublic: boolean;
  stripeId: string;
}

/* Delete Product Mutation */
const DELETE_PRODUCT = gql`
mutation deleteProduct($id: ID!) {
  deleteProduct(id: $id) {
    id
  }
}
`;

type DeleteProductResponse = {
  deleteProduct: {
    id: string;
  } | null;
}

/* Create Role Mutation */
const CREATE_PRODUCT_ROLE = gql`
mutation createProductRole($input: CreateProductRoleInput!) {
  createProductRole(input: $input) {
    id
    name
    description
    sortOrder
    isBillable
  }
}
`;

type CreateProductRoleResponse = {
  createProductRole: Role | null;
}

type CreateProductRoleInput = {
  slug: string;
  name: string;
  description: string;
  isBillable: boolean;
}

/* Edit Role Mutation */
const EDIT_PRODUCT_ROLE = gql`
mutation editProductRole($id: ID!, $input: EditProductRoleInput!) {
  editProductRole(id: $id, input: $input) {
    id
    name
    description
    sortOrder
  }
}
`;

type EditProductRoleResponse = {
  editProductRole: Role | null;
}

type EditProductRoleInput = {
  slug: string;
  name: string;
  description: string;
}

/* Delete Role Mutation */
const DELETE_PRODUCT_ROLE = gql`
mutation deleteProductRole($id: ID!) {
  deleteProductRole(id: $id) {
    id
  }
}
`;

type DeleteProductRoleResponse = {
  deleteProductRole: {
    id: string;
  } | null;
}

/* Change Role Sort Order Mutation */
const CHANGE_PRODUCT_ROLE_SORT_ORDER = gql`
mutation changeProductRoleSortOrder($id: ID!, $sortOrder: Int!) {
  changeProductRoleSortOrder(id: $id, sortOrder: $sortOrder) {
    id
    name
    description
    sortOrder
  }
}
`;

type ChangeProductRoleSortOrderResponse = {
  changeProductRoleSortOrder: Role | null;
}

/* Create Plan Mutation */
const CREATE_PLAN = gql`
mutation createProductPlan($input: CreateProductPlanInput!) {
  createProductPlan(input: $input) {
    id
    name
    description
    type
  }
}
`;

type CreatePlanResponse = {
  createProductPlan: Plan | null;
}

type CreatePlanInput = {
  name: string;
  description: string;
  type: string;
  cost: string;
  costType: string;
  userLimit: string | null;
  stripeId: string | null;
  isPublic: boolean;
}

export default function ProductDetails() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { addAlert } = useAlertState();
  const { data: productData, loading: productIsLoading } = useQuery<GetProductDetailsResponse>(GET_PRODUCT, {
    variables: {
      id: productId,
    },
  });
  const { data: plansData, loading: plansAreLoading } = useQuery<GetProductPlansResponse>(GET_PRODUCT_PLANS, {
    variables: {
      productId: productId,
    },
  });
  const [editProduct] = useMutation<EditProductResponse>(EDIT_PRODUCT);
  const [deleteProduct] = useMutation<DeleteProductResponse>(DELETE_PRODUCT);
  const [createProductRole] = useMutation<CreateProductRoleResponse>(CREATE_PRODUCT_ROLE, {
    update(cache, { data }) {
      const { product } = cache.readQuery<GetProductDetailsResponse>({
        query: GET_PRODUCT,
        variables: {
          id: productId,
        },
      }) || { product: null };

      if (product) {
        cache.writeQuery({
          query: GET_PRODUCT,
          variables: {
            id: productId,
          },
          data: {
            product: {
              ...product,
              roles: [
                ...product.roles,
                data?.createProductRole,
              ],
            },
          },
        });
      }
    },
  });
  const [editProductRole] = useMutation<EditProductRoleResponse>(EDIT_PRODUCT_ROLE);
  const [deleteProductRole] = useMutation<DeleteProductRoleResponse>(DELETE_PRODUCT_ROLE, {
    update(cache, { data }) {
      if (!productData?.product) {
        return;
      }

      cache.modify({
        id: cache.identify(productData.product),
        fields: {
          roles(existingRoles, { readField }) {
            return existingRoles.filter((roleRef: any) => {
              return readField('id', roleRef) !== data?.deleteProductRole?.id;
            });
          },
        },
      });
    },
  });
  const [changeProductRoleSortOrder] = useMutation<ChangeProductRoleSortOrderResponse>(CHANGE_PRODUCT_ROLE_SORT_ORDER, {
    update(cache, { data }) {
      if (!productData?.product || !data?.changeProductRoleSortOrder) {
        return;
      }

      cache.modify({
        id: cache.identify(productData.product),
        fields: {
          roles(existingRoles, { readField }) {
            const newRoles = [...existingRoles];
            const roleIndex = newRoles.findIndex((roleRef: any) => {
              return readField('id', roleRef) === data?.changeProductRoleSortOrder?.id;
            });

            newRoles.splice(roleIndex, 1);
            newRoles.splice(data?.changeProductRoleSortOrder?.sortOrder || 0, 0, existingRoles[roleIndex]);

            return newRoles;
          },
        },
      });
    },
  });
  const [createPlan] = useMutation<CreatePlanResponse>(CREATE_PLAN, {
    refetchQueries: [{
      query: GET_PRODUCT_PLANS,
      variables: {
        productId: productId,
      },
    }]
  });

  const handleValidateSlug = (_: string, value: string) => {
    if (!IsValidSlug(value)) {
      return 'Slug must only contain lowercase letters, numbers, and dashes.'
    }

    return null;
  }

  const handleEditProduct = async (values: EditProductInput) => {
    const { errors } = await editProduct({
      variables: {
        id: productId,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          isPublic: values.isPublic,
          stripeId: values.stripeId ? values.stripeId : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to update product' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const editProductModal = (
    <FormModal<EditProductInput> title='Edit Product' onSubmit={handleEditProduct} initialValues={{ name: productData?.product?.name || '', description: productData?.product?.description || '', isPublic: productData?.product?.isPublic || false, stripeId: productData?.product?.stripeId || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Product Name' name='name' required />
        <TextArea label='Description' name='description' />
        <TextField label='Stripe ID' name='stripeId' />
        <Checkbox label='Is Public?' description="This will allow anybody to view this product's basic information." name='isPublic' />
      </View>
    </FormModal>
  );

  const handleDeleteProduct = async () => {
    const { errors } = await deleteProduct({
      variables: {
        id: productId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to delete product' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    } else {
      navigate('/products');
    }
  }

  const deleteProductModal = (
    <ConfirmModal title='Delete Product?' confirmLabel='Delete' destructive onConfirm={handleDeleteProduct}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This product will be permanently deleted.</StyledParagraph>
        <InfoPanel type='info'>
          <StyledParagraph>This action will fail if the product is still assigned to any plans or users.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  const handleCreateProductRole = async (values: CreateProductRoleInput) => {
    const { errors } = await createProductRole({
      variables: {
        input: {
          productId: productId,
          slug: values.slug,
          name: values.name,
          description: values.description ? values.description : null,
          isBillable: values.isBillable,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to create role' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const createProductRoleModal = (
    <FormModal<CreateProductRoleInput> title='Create Role' submitLabel='Create' onSubmit={handleCreateProductRole} initialValues={{ slug: '', name: '', description: '', isBillable: false }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Slug' description='A unique identifier for the product role' name='slug' validate={handleValidateSlug} required />
        <TextField label='Role Name' name='name' required />
        <TextArea label='Description' name='description' />
        <Checkbox label='Billable' description='Assigning a user to this role will take up a seat' name='isBillable' />
        <InfoPanel type='info'>
          <StyledParagraph>You must create a product role in <span style={{ fontWeight: 600 }}>staging and production</span> with the same ID before adding the product role to any code.</StyledParagraph>
        </InfoPanel>
      </View>
    </FormModal>
  );

  const handleEditProductRole = async (values: EditProductRoleInput) => {
    const { errors } = await editProductRole({
      variables: {
        id: values.slug,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to update role' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const editProductRoleModal = (
    <FormModal<EditProductRoleInput> title='Edit Role' onSubmit={handleEditProductRole} initialValues={{ slug: '', name: '', description: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Role Name' name='name' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  const handleDeleteProductRole = async (slug: string) => {
    const { errors } = await deleteProductRole({
      variables: {
        id: slug,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to delete role' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const deleteProductRoleModal = (
    <ConfirmModal title='Delete Role?' confirmLabel='Delete' destructive onConfirm={handleDeleteProductRole}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This role will be permanently deleted.</StyledParagraph>
        <InfoPanel type='info'>
          <StyledParagraph>This action will fail if the role is still assigned to any users.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  const handleChangeRoleSortOrder = async (id: string, newSortOrder: number) => {
    const { errors } = await changeProductRoleSortOrder({
      variables: {
        id: id,
        sortOrder: newSortOrder,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to change role sort order' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const handleCreatePlan = async (values: CreatePlanInput) => {
    let userLimit: number | null = parseInt(values.userLimit || '0');
    if (isNaN(userLimit)) {
      userLimit = null;
    } else if (userLimit <= 0) {
      userLimit = null;
    }

    const { errors } = await createPlan({
      variables: {
        input: {
          productId: productId,
          name: values.name,
          description: values.description ? values.description : null,
          type: values.type,
          cost: values.cost,
          costType: values.costType,
          userLimit: userLimit,
          stripeId: values.stripeId ? values.stripeId : null,
          isPublic: values.isPublic,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to create plan' type='error' description={errors[0].message} id={id} />
      );

      addAlert(id, alert);
    }
  }

  const createPlanModal = (
    <FormModal<CreatePlanInput> title='Create Plan' onSubmit={handleCreatePlan} submitLabel='Create Plan' initialValues={{ name: '', description: '', type: '', cost: '', costType: '', userLimit: '', stripeId: '', isPublic: false }}>
      <View style={{ gap: '16px' }}>
        <InfoPanel type='warning'>
          <StyledParagraph>If this plan is billable, you will also need to create a new Price for this Product in Stripe.</StyledParagraph>
        </InfoPanel>

        <TextField label='Plan Name' name='name' required />

        <TextArea label='Description' name='description' />

        <SingleSelect label='Plan Type' name='type' required>
          <Choice label='Monthly' value='MONTHLY' />
          <Choice label='Annual' value='ANNUAL' />
        </SingleSelect>

        <TextField label='Cost' name='cost' type='currency' required />

        <SingleSelect label='Cost Type' name='costType' required>
          <Choice label='Flat' value='FLAT' />
          <Choice label='Per User' value='PER_USER' />
        </SingleSelect>

        <TextField label='User Limit' name='userLimit' type='number' />

        <TextField label='Stripe ID' name='stripeId' />

        <Checkbox label='Is Public?' description='This plan will be visible to all users and displayed on the pricing pages.' name='isPublic' />
      </View>
    </FormModal>
  );

  if (productIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!productData?.product) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Products' to='/products' />
          <Breadcrumb label={productData.product.name} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>{productData.product.name}</StyledHeading>
          <HasEmployeePermission permissions={['canEditProducts', 'canDeleteProducts']}>
            <ModalLauncher modal={editProductModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={deleteProductModal}>
                  {({ openModal: openDeleteModal }) => (
                    <ActionMenu alignment='right'>
                      <ActionItem label='Edit' onClick={openEditModal} />
                      <HasEmployeePermission permissions={['canEditProducts', 'canDeleteProducts']}>
                        <ActionItem label='Delete' onClick={openDeleteModal} />
                      </HasEmployeePermission>
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasEmployeePermission>
        </View>
      </Cell>
      <Cell lg={6} md={6} sm={4}>
        <Card>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h6'>Product Info</StyledHeading>
            <View>
              <StyledCaption>ID</StyledCaption>
              <StyledParagraph>{productData.product.id}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Name</StyledCaption>
              <StyledParagraph>{productData.product.name}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Description</StyledCaption>
              <StyledParagraph>{productData.product.description || '----'}</StyledParagraph>
            </View>
            <View>
              <StyledCaption>Is Public?</StyledCaption>
              {productData.product.isPublic ? <StyledParagraph style={{ color: Colors.primary500, fontWeight: 600 }}>YES</StyledParagraph> : <StyledParagraph style={{ color: Colors.error500, fontWeight: 600 }}>NO</StyledParagraph>}
            </View>
            <View>
              <StyledCaption>Stripe ID</StyledCaption>
              <StyledParagraph>{productData.product.stripeId ? <Link href={`https://dashboard.stripe.com${(!window.location.href.includes('barscience.us') || window.location.href.includes('-dev') ? '/test' : '')}/products/${productData.product.stripeId}`}>{productData.product.stripeId}</Link> : '----'}</StyledParagraph>
            </View>
          </View>
        </Card>
      </Cell>
      <Cell lg={6} md={6} sm={4}>
        <Card>
          <View style={{ gap: '16px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
              <StyledHeading tag='h6'>Roles</StyledHeading>
              <HasEmployeePermission permissions={['canEditProductRoles']}>
                <ModalLauncher modal={createProductRoleModal}>
                  {({ openModal }) => (
                    <Button label='Create Role' variant='tertiary' role='button' action={openModal} />
                  )}
                </ModalLauncher>
              </HasEmployeePermission>
            </View>
            {productData.product.roles.map((role, index) => {
              return (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} key={index}>
                  <View>
                    <StyledParagraph><span style={{ fontWeight: 600 }}>{role.name}</span> <span style={{ color: Colors.neutral700, fontSize: '12px' }}>(ID: {role.id})</span> <span style={{ color: Colors.neutral700, fontSize: '12px' }}>({role.isBillable ? 'BILLABLE' : 'NOT BILLABLE'})</span></StyledParagraph>
                    <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{role.description}</StyledParagraph>
                  </View>

                  <HasEmployeePermission permissions={['canEditProductRoles', 'canDeleteProductRoles']}>
                    <ModalLauncher modal={editProductRoleModal}>
                      {({ openModal: openEditModal }) => (
                        <ModalLauncher modal={deleteProductRoleModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu alignment='right'>
                              <ActionItem label='Edit' onClick={() => { openEditModal({ slug: role.id, name: role.name, description: role.description || '' }); }} />
                              {role.sortOrder > 0 && <ActionItem label='Move Up' onClick={() => { handleChangeRoleSortOrder(role.id, role.sortOrder - 1); }} />}
                              {role.sortOrder < (productData.product?.roles.length || 1) - 1 && <ActionItem label='Move Down' onClick={() => { handleChangeRoleSortOrder(role.id, role.sortOrder + 1); }} />}
                              <HasEmployeePermission permissions={['canDeleteProductRoles']}>
                                <ActionItem label='Delete' onClick={() => { openDeleteModal(role.id); }} />
                              </HasEmployeePermission>
                            </ActionMenu>
                          )}
                        </ModalLauncher>

                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              );
            })}
          </View>
        </Card >
      </Cell >
      <Cell lg={6} md={6} sm={4}>
        <Card>
          {plansAreLoading ?
            <View>
              <CircularSpinner size='medium' />
            </View>
            :
            <View style={{ gap: '16px' }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <StyledHeading tag='h6'>Plans</StyledHeading>
                <HasEmployeePermission permissions={['canEditProductPlans']}>
                  <ModalLauncher modal={createPlanModal}>
                    {({ openModal }) => (
                      <Button label='Create Plan' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content' }} />
                    )}
                  </ModalLauncher>
                </HasEmployeePermission>
              </View>
              {plansData?.plans.map((plan, index) => (
                <View key={index}>
                  <Link href={`/plans/${plan.id}`}><StyledParagraph bold>{plan.name} ({plan.type})</StyledParagraph></Link>
                  <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{plan.description}</StyledParagraph>
                </View>
              ))}
            </View>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}