import { gql, useQuery } from "@apollo/client";

/* Check Flag Query */
const CHECK_FLAG_QUERY = gql`
query checkFeatureFlag($flagId: ID!) {
  isUserPassing: doesUserPassFeatureFlag(flagId: $flagId)
}
`;

type CheckFlagQueryResponse = {
  isUserPassing: boolean;
}


/* Component Props */
type UseFlagCheckProps = {
  flagId: string;
}

export default function useFlagCheck(props: UseFlagCheckProps) {
  const { data, loading, error } = useQuery<CheckFlagQueryResponse>(CHECK_FLAG_QUERY, {
    variables: {
      flagId: props.flagId,
    },
  });

  return { data, loading, error }
}