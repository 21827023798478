import { Card, Cell, Link, StandardGrid, StyledHeading, View } from "@barscience/global-components";

export default function Tools() {
  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Tools and Resources</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ gap: '32px' }}>
            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Devs</StyledHeading>
              <Link href='https://bitbucket.org/bar-science/workspace/repositories'>Code Repositories (Bitbucket)</Link>
              <Link href='https://barscience.atlassian.net/jira/projects?page=1&sortKey=name&sortOrder=ASC&types=software'>Jira Projects</Link>
              <Link href='https://lab.barscience.us' target='_blank'>Component Library</Link>
              <Link href='https://www.chromatic.com/builds?appId=62e1f8c853f954ed0d37dd08'>Component Library Testing</Link>
              <Link href='https://studio.apollographql.com/org/bar-science/graphs'>API Schema (Apollo Studio)</Link>
              <Link href='https://console.cloud.google.com/home/dashboard'>Google Cloud</Link>
              <Link href='https://domains.google.com/registrar/barscience.us' target='_blank'>Domain Management</Link>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Product Management</StyledHeading>
              <Link href='https://barscience.atlassian.net/jira/polaris/projects/RD/ideas/view/1457917'>Feature Requests</Link>
              <Link href='https://barscience.atlassian.net/jira/software/c/projects/ROAD/boards/41/timeline'>Dev Roadmap</Link>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Design</StyledHeading>
              <Link href='https://barscience.atlassian.net/jira/core/projects/DESIGN/board'>Design Project (Jira)</Link>
              <Link href='https://fontawesome.com/'>Icons</Link>
              <Link href='https://iconscout.com/'>Illustrations</Link>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Support</StyledHeading>
              <Link href='https://dashboard.stripe.com/'>Advanced Billing Management</Link>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Marketing</StyledHeading>
              <Link href='https://app.sendgrid.com/'>Email Sending (SendGrid)</Link>
            </View>
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}