import { Checkbox, NoPermission, StandardGrid, StyledHeading, StyledParagraph, View, useAuthState } from "@barscience/global-components";
import { ApolloExplorer } from '@apollo/explorer/react';
import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { getIntrospectionQuery } from 'graphql';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    height: '100%',
  },
});

const INTROSPECT_SCHEMA = gql(getIntrospectionQuery());

export default function APIExplorer() {
  const { state } = useAuthState();
  const [authenticateRequests, setAuthenticateRequests] = useState<boolean>(true);
  const [introspectGraph, { data }] = useLazyQuery(INTROSPECT_SCHEMA);

  useEffect(() => {
    if (!window.location.href.includes('barscience.us')) {
      introspectGraph();
    }
  }, [introspectGraph]);

  const handleRequest = (_: string, options: Omit<RequestInit, 'headers'> & {
    headers: Record<string, string>;
  }) => {
    let endpointUrl = 'https://api.barscience.us';
    if (window.location.href.includes('-dev')) {
      endpointUrl = 'https://api-dev.barscience.us';
    } else if (!window.location.href.includes('barscience.us')) {
      endpointUrl = `http://${window.location.hostname}:6001`;
    }

    return fetch(endpointUrl, {
      ...options,
      headers: {
        ...options.headers,
      },
      credentials: authenticateRequests ? 'include' : 'omit',
    })
  }

  if (window.location.href.includes('barscience.us') && !state.user?.bsEmployeePermissions?.canRunGqlQueries) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  return (
    <View style={{ height: '100%', width: '100%' }}>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between', minHeight: '50px', padding: '8px 16px' }}>
        <StyledHeading tag='h5'>API Explorer</StyledHeading>
        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
          <Checkbox label='' name='authenticateRequests' checked={authenticateRequests} onChange={(_, value) => { setAuthenticateRequests(value); }} style={{ marginBottom: '2px' }} />
          <StyledParagraph>Authenticate requests</StyledParagraph>
        </View>
      </View>
      {(!window.location.href.includes('barscience.us') && data) ?
        <ApolloExplorer
          schema={data}
          endpointUrl='http://localhost:6001'
          persistExplorerState={false}
          initialState={{
            document: ``,
            variables: {},
            headers: {},
            displayOptions: {
              showHeadersAndEnvVars: false,
              docsPanelState: 'open',
              theme: 'light',
            },
          }}
          className={css(styles.container)}
          handleRequest={handleRequest}
        />
        :
        <ApolloExplorer
          graphRef={getGraphRef()}
          persistExplorerState={false}
          initialState={{
            document: ``,
            variables: {},
            headers: {},
            displayOptions: {
              showHeadersAndEnvVars: false,
              docsPanelState: 'open',
              theme: 'light',
            },
          }}
          className={css(styles.container)}
          handleRequest={handleRequest}
        />
      }
    </View>
  );
}

const getGraphRef = () => {
  if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
    return 'Bar-Science-Global@development';
  } else {
    return 'Bar-Science-Global@current';
  }
}