import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Choice, FormModal, Link, ModalLauncher, NoPermission, SingleSelect, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, useAuthState, useForm } from "@barscience/global-components";
import { useSearchParams } from "react-router-dom";

/* User Search Query */
const USER_SEARCH_QUERY = gql`
query lookupUsers($input: UserSearchInput!, $page: Int!) {
  userSearch(input: $input, page: $page) {
    id
    firstName
    lastName
    email
    created
    lastLoginTimestamp
    org {
      id
      name
    }
  }
}
`;

type UserSearchResponse = {
  userSearch: User[] | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  created: string | null;
  lastLoginTimestamp: string | null;
  org: Org | null;
}

type Org = {
  id: string;
  name: string;
}

type SearchInput = {
  query: string;
  firstName: string;
  lastName: string;
  type: SearchInputType;
}

type SearchInputType = 'ID' | 'EMAIL' | 'PHONE' | 'IP' | 'NAME'

/* Invite User Mutation */
const INVITE_USER_MUTATION = gql`
mutation inviteUser($email: String!, $firstName: String!, $lastName: String!, $orgId: String) {
  success: inviteUser(email: $email, firstName: $firstName, lastName: $lastName, orgId: $orgId)
}
`;

type InviteUserResponse = {
  success: boolean;
}

type InviteUserInput = {
  firstName: string;
  lastName: string;
  email: string;
  orgId: string;
}

export default function UserSearch() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [searchParams, setSearchParams] = useSearchParams({
    type: 'ID',
  });
  const [search, { data: searchData, loading: searchIsLoading }] = useLazyQuery<UserSearchResponse>(USER_SEARCH_QUERY, {
    errorPolicy: 'all',
  });
  const [inviteUser] = useMutation<InviteUserResponse>(INVITE_USER_MUTATION);

  const searchForm = useForm<SearchInput>({
    initialValues: {
      query: searchParams.get('query') || '',
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      type: searchParams.get('type') as SearchInputType || 'ID',
    },
    onSubmit: async (values) => {
      let searchQuery = values.query;
      if (values.type === 'NAME') {
        searchQuery = `${values.firstName}+${values.lastName}`;
      }

      await search({
        variables: {
          input: {
            query: searchQuery,
            type: values.type,
          },
          page: 0,
        },
      });
    },
  });

  const validateInput = (_: string, value: string) => {
    if (searchForm.values.type === 'ID') {
      if (!value.includes('bsid_')) {
        return '';
      }
    } else if (searchForm.values.type === 'EMAIL') {
      if (!value.includes('@') || !value.includes('.')) {
        return '';
      }
    }

    return null;
  }

  const handleChangeType = (newType: SearchInputType) => {
    if (newType === 'NAME') {
      setSearchParams({
        type: newType,
        firstName: searchForm.values.firstName,
        lastName: searchForm.values.lastName,
      });
    } else {
      setSearchParams({
        type: newType,
        query: searchForm.values.query,
      });
    }
  }

  const handleInviteUser = async (values: InviteUserInput) => {
    const { data, errors } = await inviteUser({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        orgId: values.orgId ? values.orgId : null,
      },
    });

    if (data?.success) {
      const id = generateId();
      const alert = <StandardAlert title='User has been invited' description='The user will receive an email with a link to create their account.' type='success' id={id} />
      addAlert(id, alert, 30);
    } else if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error inviting user' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert, 30);
    }
  }

  const inviteUserModal = (
    <FormModal<InviteUserInput> title='Invite User' initialValues={{ firstName: '', lastName: '', email: '', orgId: '' }} onSubmit={handleInviteUser} submitLabel='Send Invite'>
      <View style={{ gap: '16px' }}>
        <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <TextField label='First Name' name='firstName' required />
          <TextField label='Last Name' name='lastName' required />
        </View>

        <TextField label='Email' name='email' type='email' required />

        <TextField label='Org ID' name='orgId' description='The user will automatically be added to this org after accepting the invite' />
      </View>
    </FormModal>
  );

  if (!state.user?.bsEmployeePermissions?.canLookupUsers) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>User Search</StyledHeading>
          <ModalLauncher modal={inviteUserModal}>
            {({ openModal }) => (
              <Button label='Invite User' variant='secondary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
            <SingleSelect label='Search By' name='type' value={searchForm.values.type} error={searchForm.errors.type} onChange={(name, value) => { handleChangeType(value as SearchInputType || 'ID'); searchForm.handleChange(name, value); }} onValidate={searchForm.handleValidate} style={{ maxWidth: '200px' }}>
              <Choice label='BSID' value='ID' />
              <Choice label='Email' value='EMAIL' />
              <Choice label='Phone' value='PHONE' />
              <Choice label='IP Address' value='IP' />
              <Choice label='Name' value='NAME' />
            </SingleSelect>
            {searchForm.values.type === 'NAME' ?
              <>
                <TextField label='First Name' name='firstName' value={searchForm.values.firstName} error={searchForm.errors.firstName} onChange={searchForm.handleChange} onBlur={(_, value) => { setSearchParams({ type: searchForm.values.type, firstName: value, lastName: searchForm.values.lastName }); }} onValidate={searchForm.handleValidate} style={{ maxWidth: '300px', '@media (max-width: 767px)': { maxWidth: '100%', width: '50%' } }} />
                <TextField label='Last Name' name='lastName' value={searchForm.values.lastName} error={searchForm.errors.lastName} onChange={searchForm.handleChange} onBlur={(_, value) => { setSearchParams({ type: searchForm.values.type, firstName: searchForm.values.firstName, lastName: value }); }} onValidate={searchForm.handleValidate} style={{ maxWidth: '300px', '@media (max-width: 767px)': { maxWidth: '50%', width: '50%' } }} />
              </>
              :
              <TextField label='' name='query' placeholder='Enter search query' value={searchForm.values.query} error={searchForm.errors.query} onChange={searchForm.handleChange} onBlur={(_, value) => { setSearchParams({ type: searchForm.values.type, query: value }); }} onValidate={searchForm.handleValidate} validate={validateInput} style={{ maxWidth: '500px', '@media (max-width: 767px)': { maxWidth: '100%', width: '100%' } }} />
            }
            <Button label='Search' variant='primary' role='button' action={searchForm.handleSubmit} disabled={searchForm.hasError || (searchForm.values.type !== 'NAME' && searchForm.values.query === '') || (searchForm.values.type === 'NAME' && (searchForm.values.firstName === '' || searchForm.values.lastName === ''))} loading={searchIsLoading} />
          </View>
        </Card>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {searchIsLoading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ gap: '16px' }}>
            {searchData ?
              searchData.userSearch?.map((user, index) => (
                <Card size='medium' key={index} style={{ maxWidth: '600px' }}>
                  <View style={{ gap: '16px' }}>
                    <Link href={'/users/' + user.id}><StyledHeading tag='h6'>{user.firstName} {user.lastName}</StyledHeading></Link>

                    <View>
                      <StyledCaption>BSID</StyledCaption>
                      <StyledParagraph>{user.id}</StyledParagraph>
                    </View>

                    <View>
                      <StyledCaption>Email</StyledCaption>
                      <StyledParagraph>{user.email}</StyledParagraph>
                    </View>

                    <View>
                      <StyledCaption>Last Login</StyledCaption>
                      <StyledParagraph>{user.lastLoginTimestamp ? (new Date(user.lastLoginTimestamp).toLocaleString()) : '----'}</StyledParagraph>
                    </View>

                    <View>
                      <StyledCaption>Account Created</StyledCaption>
                      <StyledParagraph>{user.created ? (new Date(user.created).toLocaleString()) : '----'}</StyledParagraph>
                    </View>

                    <View>
                      <StyledCaption>Organization</StyledCaption>
                      {user.org ?
                        <Link href={'/orgs/' + user.org.id}><StyledParagraph>{user.org.name}</StyledParagraph></Link>
                        :
                        <StyledParagraph>None</StyledParagraph>
                      }
                    </View>
                  </View>
                </Card>
              ))
              :
              <></>
            }
            {(searchData?.userSearch?.length === 0 || searchData?.userSearch === null) && <StyledParagraph>No users found.</StyledParagraph>}
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}