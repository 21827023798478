import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, Colors, ConfirmModal, Form, FormModal, FormModalValueProvider, Icon, Icons, InfoModal, InfoPanel, ModalLauncher, NoPermission, NotFound, Radio, RadioGroup, Row, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, TextEditor, TextField, View, useAuthState, useForm } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";
import { useNavigate, useParams } from "react-router-dom";
import FeatureFlagRuleCard from "./FeatureFlagRuleCard";
import { useState } from "react";

/* Get Feature Flag Query */
const GET_FEATURE_FLAG = gql`
query getFeatureFlagDetails($id: ID!) {
  featureFlag(id: $id) {
    id
    description
    enabled
    createdAt
    service
    defaultValue
    rules {
      ... on FeatureFlagRuleSpecificUsers {
        id
        action
        sortOrder
        users
      }
      ... on FeatureFlagRuleSpecificOrgs {
        id
        action
        sortOrder
        orgs
      }
      ... on FeatureFlagRuleBSEmployees {
        id
        sortOrder
        action
      }
      ... on FeatureFlagRulePercentOfOrgs {
        id
        action
        sortOrder
        percent
      }
    }
  }
}
`;

type GetFeatureFlagDetailsResponse = {
  featureFlag: FeatureFlag | null;
}

type FeatureFlag = {
  id: string;
  description: string;
  enabled: boolean;
  createdAt: string;
  service: string;
  defaultValue: boolean;
  rules: FeatureFlagRule[];
}

/* Create Feature Flag Rule Mutation */
const CREATE_FEATURE_FLAG_RULE = gql`
mutation createFeatureFlagRule($flagId: ID!, $input: FeatureFlagRuleInput!) {
  createFeatureFlagRule(flagId: $flagId, input: $input) {
    ... on FeatureFlagRuleSpecificUsers {
      id
      action
      sortOrder
      users
    }
    ... on FeatureFlagRuleSpecificOrgs {
      id
      action
      sortOrder
      orgs
    }
    ... on FeatureFlagRuleBSEmployees {
      id
      sortOrder
      action
    }
    ... on FeatureFlagRulePercentOfOrgs {
      id
      action
      sortOrder
      percent
    }
  }
}
`;

type CreateFeatureFlagRuleResponse = {
  createFeatureFlagRule: FeatureFlagRule;
}

type AddRuleInput = {
  type: 'SPECIFIC_USERS' | 'SPECIFIC_ORGS' | 'BS_EMPLOYEES' | 'PERCENT_OF_ORGS';
  action: 'ON' | 'OFF';
  percent: '';
}

/* Edit Feature Flag Status Mutation */
const EDIT_FEATURE_FLAG_STATUS = gql`
mutation setFeatureFlagStatus($flagId: ID!, $enabled: Boolean!) {
  setFeatureFlagStatus(flagId: $flagId, enabled: $enabled) {
    id
    enabled
  }
}
`;

type EditFeatureFlagStatusResponse = {
  setFeatureFlagStatus: {
    id: string;
    enabled: boolean;
  } | null;
}

export type FeatureFlagRule = {
  id: string;
  action: 'ON' | 'OFF';
  sortOrder: number;
  users: string[];
  __typename: 'FeatureFlagRuleSpecificUsers';
} | {
  id: string;
  action: 'ON' | 'OFF';
  sortOrder: number;
  orgs: string[];
  __typename: 'FeatureFlagRuleSpecificOrgs';
} | {
  id: string;
  sortOrder: number;
  action: 'ON' | 'OFF';
  __typename: 'FeatureFlagRuleBSEmployees';
} | {
  id: string;
  action: 'ON' | 'OFF';
  sortOrder: number;
  percent: number;
  __typename: 'FeatureFlagRulePercentOfOrgs';
}

/* Edit Feature Flag Description Mutation */
const EDIT_FEATURE_FLAG_DESCRIPTION = gql`
mutation editFeatureFlagDescription($flagId: ID!, $description: String!) {
  editFeatureFlagDescription(flagId: $flagId, description: $description) {
    id
    description
  }
}
`;

type EditFeatureFlagDescriptionResponse = {
  editFeatureFlagDescription: {
    id: string;
    description: string;
  } | null;
}

type EditFeatureFlagDescriptionInput = {
  description: string;
}

/* Edit Feature Flag Details Mutation */
const EDIT_FEATURE_FLAG_DETAILS = gql`
mutation editFeatureFlagDetails($flagId: ID!, $defaultValue: Boolean!, $service: FeatureFlagService!) {
  editFeatureFlagDetails(flagId: $flagId, defaultValue: $defaultValue, service: $service) {
    id
    description
    enabled
    createdAt
    service
    defaultValue
  }
}
`;

type EditFeatureFlagDetailsResponse = {
  editFeatureFlagDetails: {
    id: string;
    description: string;
    enabled: boolean;
    createdAt: string;
    service: string;
    defaultValue: boolean;
  } | null;
}

type EditFeatureFlagDetailsInput = {
  defaultValue: string;
  service: string;
}

/* Delete Feature Flag Mutation */
const DELETE_FEATURE_FLAG = gql`
mutation deleteFeatureFlag($flagId: ID!) {
  deleteFeatureFlag(flagId: $flagId) {
    id
  }
}
`;

type DeleteFeatureFlagResponse = {
  deleteFeatureFlag: {
    id: string;
  } | null;
}

/* Check User Passes Query */
const CHECK_USER_PASSES = gql`
query doesUserPassFeatureFlag($flagId: ID!, $userId: ID) {
  doesUserPassFeatureFlag(flagId: $flagId, userId: $userId)
}
`;

type CheckUserPassesResponse = {
  doesUserPassFeatureFlag: boolean;
}

type CheckUserPassesInput = {
  userId: string;
}

const styles = StyleSheet.create({
  flagLabelShared: {
    fontWeight: 'bold',
  },
  flagLabelEnabled: {
    color: Colors.primary500,
  },
  flagLabelDisabled: {
    color: Colors.error500,
  }
});

export default function FeatureFlagDetails() {
  const navigate = useNavigate();
  const { state } = useAuthState();
  const { flagId } = useParams();
  const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);
  const { data: flagData, loading: flagIsLoading } = useQuery<GetFeatureFlagDetailsResponse>(GET_FEATURE_FLAG, {
    variables: {
      id: flagId,
    },
  });
  const [createFeatureFlagRule] = useMutation<CreateFeatureFlagRuleResponse>(CREATE_FEATURE_FLAG_RULE, {
    update(cache, { data }) {
      if (!data || !flagData || !flagData.featureFlag) {
        return;
      }

      cache.modify({
        id: cache.identify(flagData.featureFlag),
        fields: {
          rules(existingRules = []) {
            const newRuleRef = cache.writeFragment({
              data: data.createFeatureFlagRule,
              fragment: gql`
                  fragment NewFeatureFlagRule on FeatureFlagRule {
                    id
                    action
                    sortOrder
                    users
                    orgs
                    percent
                  }
                `,
            });
            return [...existingRules, newRuleRef];
          }
        }
      });
    }
  });
  const [editFeatureFlagStatus] = useMutation<EditFeatureFlagStatusResponse>(EDIT_FEATURE_FLAG_STATUS);

  const handleAddRule = async (values: AddRuleInput) => {
    await createFeatureFlagRule({
      variables: {
        flagId: flagId,
        input: {
          type: values.type,
          action: values.action,
          percent: values.type === 'PERCENT_OF_ORGS' ? Math.floor(parseFloat(values.percent)) : null,
        },
      },
    });
  }
  const [editFeatureFlagDescription] = useMutation<EditFeatureFlagDescriptionResponse>(EDIT_FEATURE_FLAG_DESCRIPTION);
  const [editFeatureFlagDetails] = useMutation<EditFeatureFlagDetailsResponse>(EDIT_FEATURE_FLAG_DETAILS);
  const [deleteFeatureFlag] = useMutation<DeleteFeatureFlagResponse>(DELETE_FEATURE_FLAG, {
    update(cache, { data }) {
      if (!data || !flagData || !flagData.featureFlag) {
        return;
      }

      cache.evict({ id: cache.identify(flagData.featureFlag) });
    },
  });
  const [checkUserPasses, { data: userPassesData }] = useLazyQuery<CheckUserPassesResponse>(CHECK_USER_PASSES, {
    fetchPolicy: 'network-only',
  });

  const handleEnableFlag = async () => {
    await editFeatureFlagStatus({
      variables: {
        flagId: flagId,
        enabled: true,
      },
    });
  }

  const handleDisableFlag = async () => {
    await editFeatureFlagStatus({
      variables: {
        flagId: flagId,
        enabled: false,
      },
    });
  }

  const handleEditDescription = async (values: EditFeatureFlagDescriptionInput) => {
    await editFeatureFlagDescription({
      variables: {
        flagId: flagId,
        description: values.description,
      },
    });

    setIsEditingDescription(false);
  }

  const editDescriptionForm = useForm<EditFeatureFlagDescriptionInput>({
    initialValues: {
      description: '',
    },
    onSubmit: handleEditDescription,
  });

  const handleEditDetails = async (values: EditFeatureFlagDetailsInput) => {
    let defaultValue = false;
    if (values.defaultValue === 'TRUE') {
      defaultValue = true;
    }

    await editFeatureFlagDetails({
      variables: {
        flagId: flagId,
        defaultValue: defaultValue,
        service: values.service,
      },
    });
  }

  const handleDeleteFlag = async () => {
    const { data } = await deleteFeatureFlag({
      variables: {
        flagId: flagId,
      },
    });

    if (data?.deleteFeatureFlag?.id) {
      navigate('/feature-flags');
    }
  }

  const handleCheckUserPasses = async (values: CheckUserPassesInput) => {
    await checkUserPasses({
      variables: {
        flagId: flagId,
        userId: values.userId,
      },
    });
  }

  const checkUserPassesForm = useForm<CheckUserPassesInput>({
    initialValues: {
      userId: '',
    },
    onSubmit: handleCheckUserPasses,
  });

  if (!state.user?.bsEmployeePermissions?.canManageFeatureFlags) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (flagIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!flagId || (!flagIsLoading && !flagData?.featureFlag)) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  const addRuleModal = (
    <FormModal<AddRuleInput> title='Add Rule' onSubmit={handleAddRule} submitLabel='Add Rule' initialValues={{ type: 'PERCENT_OF_ORGS', action: 'ON', percent: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '16px' }}>
            <RadioGroup label='Operation' name='action' required>
              <Radio label='Turn on' value='ON' />
              <Radio label='Turn off' value='OFF' />
            </RadioGroup>
            <SingleSelect label='For' name='type' required validate={(_, value) => { if (value !== 'PERCENT_OF_ORGS') { setError && setError('percent', null); } return null; }}>
              <Choice label='Percent of all orgs' value='PERCENT_OF_ORGS' />
              <Choice label='Specific users' value='SPECIFIC_USERS' />
              <Choice label='Specific orgs' value='SPECIFIC_ORGS' />
              <Choice label='BS employees' value='BS_EMPLOYEES' />
            </SingleSelect>
            {(getValue && getValue('type') === 'PERCENT_OF_ORGS') &&
              <TextField label='Percent of orgs' name='percent' type='number' style={{ maxWidth: '150px' }} required />
            }
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const confirmEnableFlagModal = (
    <ConfirmModal title='Enable Flag?' confirmLabel='Enable Flag' onConfirm={handleEnableFlag}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Are you sure you want to enable this feature flag?</StyledParagraph>
        <StyledParagraph>The flag will now be evaluated using the rules instead of returning the default value.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const confirmDisableFlagModal = (
    <ConfirmModal title='Disable Flag?' confirmLabel='Disable Flag' onConfirm={handleDisableFlag}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Are you sure you want to disable this feature flag?</StyledParagraph>
        <StyledParagraph>The default value will be returned to all users, regardless of the flag rules.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const editDetailsModal = (
    <FormModal<EditFeatureFlagDetailsInput> title='Edit Feature Flag' onSubmit={handleEditDetails} initialValues={{ defaultValue: flagData?.featureFlag?.defaultValue ? 'TRUE' : 'FALSE', service: flagData?.featureFlag?.service || '' }}>
      <View style={{ gap: '16px' }}>
        <RadioGroup label='Default Value' description='This is returned to all users when the flag is disabled.' name='defaultValue' required>
          <Radio label='True' value='TRUE' />
          <Radio label='False' value='FALSE' />
        </RadioGroup>
        <SingleSelect label='Service' name='service' required>
          <Choice label='Admin' value='ADMIN' />
          <Choice label='General' value='GENERAL' />
          <Choice label='Inventory' value='INVENTORY' />
          <Choice label='Org' value='ORG' />
        </SingleSelect>
      </View>
    </FormModal>
  );

  const confirmDeleteFlagModal = (
    <FormModal<{ confirmation: string }> title='Delete Flag?' initialValues={{ confirmation: '' }} submitLabel='Delete' destructive onSubmit={handleDeleteFlag}>
      <View style={{ gap: '16px' }}>
        <InfoPanel type='warning'>
          <View style={{ gap: '16px' }}>
            <StyledParagraph><span style={{ fontWeight: 'bold' }}>WARNING:</span> This flag will be <span style={{ fontWeight: 'bold' }}>permanently deleted</span>.</StyledParagraph>
            <StyledParagraph style={{ fontStyle: 'italic' }}>Make sure all uses of this flag have been removed from source code.</StyledParagraph>
          </View>
        </InfoPanel>
        <TextField label='Type "I am completely sure" to proceed' name='confirmation' validate={(_, value) => {
          if (value.toLowerCase() === 'i am completely sure') {
            return null;
          }

          return 'Phrases do not match';
        }} required />
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Feature Flags' to='/feature-flags' />
          <Breadcrumb label={flagData?.featureFlag?.id || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>{flagData?.featureFlag?.id}</StyledHeading>
          <ModalLauncher modal={editDetailsModal}>
            {({ openModal: openEditModal }) => (
              <ModalLauncher modal={confirmDeleteFlagModal}>
                {({ openModal: openDeleteModal }) => (
                  <ActionMenu alignment='right'>
                    <ActionItem label='Edit' onClick={openEditModal} />
                    <ActionItem label='Delete' onClick={openDeleteModal} />
                  </ActionMenu>
                )}
              </ModalLauncher>
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Row>
        <Cell lg={8} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <View>
                <StyledParagraph bold>Slug</StyledParagraph>
                <StyledParagraph>{flagData?.featureFlag?.id}</StyledParagraph>
              </View>
              <View>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph bold>Description</StyledParagraph>
                  {isEditingDescription ?
                    <Button label='Save changes' variant='tertiary' role='button' action={() => { handleEditDescription(editDescriptionForm.values); }} />
                    :
                    <Button label='Edit' variant='tertiary' role='button' action={() => { setIsEditingDescription(true); }} />
                  }
                </View>
                <TextEditor name='description' isEditMode={isEditingDescription} onChange={editDescriptionForm.handleChange} value={flagData?.featureFlag?.description} editorStyle={{ minHeight: 'fit-content' }} />
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <View>
                <StyledParagraph bold>Status</StyledParagraph>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  {flagData?.featureFlag?.enabled ? <StyledParagraph><span className={css(styles.flagLabelShared, styles.flagLabelEnabled)}>ENABLED</span></StyledParagraph> : <StyledParagraph><span className={css(styles.flagLabelShared, styles.flagLabelDisabled)}>DISABLED</span></StyledParagraph>}
                  {flagData?.featureFlag?.enabled ?
                    <ModalLauncher modal={confirmDisableFlagModal}>
                      {({ openModal }) => (
                        <Button label='Disable' variant='tertiary' role='button' action={openModal} />
                      )}
                    </ModalLauncher>
                    :
                    <ModalLauncher modal={confirmEnableFlagModal}>
                      {({ openModal }) => (
                        <Button label='Enable' variant='tertiary' role='button' action={openModal} />
                      )}
                    </ModalLauncher>
                  }
                </View>
              </View>
              <View>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
                  <StyledParagraph bold>Default Value</StyledParagraph>
                  <ModalLauncher modal={defaultValueModal}>
                    {({ openModal }) => (
                      <Icon size='small' icon={Icons.Help} onClick={openModal} style={{ cursor: 'pointer' }} />
                    )}
                  </ModalLauncher>
                </View>
                <StyledParagraph>{flagData?.featureFlag?.defaultValue ? 'True' : 'False'}</StyledParagraph>
              </View>
              <View>
                <StyledParagraph bold>Service</StyledParagraph>
                <StyledParagraph>{flagData?.featureFlag?.service}</StyledParagraph>
              </View>
              <View>
                <StyledParagraph bold>Created</StyledParagraph>
                <StyledParagraph>{new Date(flagData?.featureFlag?.createdAt || '').toLocaleString()}</StyledParagraph>
              </View>
            </View>
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={8} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <StyledHeading tag='h5'>Rules</StyledHeading>
                <ModalLauncher modal={addRuleModal}>
                  {({ openModal }) => (
                    <Button label='Add Rule' variant='secondary' role='button' action={openModal} leftIcon={Icons.Plus} iconSize='small' />
                  )}
                </ModalLauncher>
              </View>
              {flagData?.featureFlag?.rules.map((rule, index) => {
                return (
                  <FeatureFlagRuleCard rule={rule} key={index} />
                );
              })}
            </View>
          </Card>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <View>
                <StyledHeading tag='h5'>Check User</StyledHeading>
                <StyledParagraph style={{ color: Colors.neutral700 }}>Use this tool to check if a user passes the flag.</StyledParagraph>
              </View>
              <Form handleSubmit={checkUserPassesForm.handleSubmit}>
                <View style={{ gap: '16px' }}>
                  <TextField label='User BSID' name='userId' value={checkUserPassesForm.values.userId} error={checkUserPassesForm.errors.userId} onChange={checkUserPassesForm.handleChange} onValidate={checkUserPassesForm.handleValidate} required />
                  <Button label='Check' variant='primary' role='button' action={checkUserPassesForm.handleSubmit} loading={checkUserPassesForm.isLoading} />
                </View>
              </Form>
              {userPassesData &&
                <StyledParagraph>The user {userPassesData.doesUserPassFeatureFlag ? <span className={css(styles.flagLabelShared, styles.flagLabelEnabled)}>DOES</span> : <span className={css(styles.flagLabelShared, styles.flagLabelDisabled)}>DOES NOT</span>} pass the flag.</StyledParagraph>
              }
            </View>
          </Card>
        </Cell>
      </Row>
    </StandardGrid>
  );
}

const defaultValueModal = (
  <InfoModal title='Default Value'>
    <StyledParagraph>When the feature flag is disabled, this value will be returned to all users, regardless of the flag rules.</StyledParagraph>
  </InfoModal>
);