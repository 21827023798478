import useFlagCheck from "./useFlagCheck";

type FlagCheckProps = {
  flagId: string;
  children: React.ReactElement;
}

export default function FlagCheck(props: FlagCheckProps) {
  const { data } = useFlagCheck({ flagId: props.flagId });

  if (data?.isUserPassing) {
    return props.children;
  } else {
    return null;
  }
}