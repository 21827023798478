import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Colors, ErrorPage, Link, NoPermission, StandardGrid, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, useAuthState, View } from "@barscience/global-components";
import { useParams } from "react-router-dom";
import { GET_ORG_PROFILE, GetOrgProfileResponse } from "./OrgUsers";
import { useEffect } from "react";

/* Get Invoices Query */
const GET_INVOICES = gql`
query getInvoicesForOrg($orgId: ID!, $next: String, $prev: String) {
  invoicesForOrg(orgId: $orgId, next: $next, prev: $prev) {
    id
    amountPaid
    created
    hostedInvoiceUrl
    isPaid
    periodStart
    periodEnd
    productName
    total
  }
}
`;

type GetInvoicesResponse = {
  invoicesForOrg: Invoice[] | null;
}

type Invoice = {
  id: string;
  amountPaid: string;
  created: string;
  hostedInvoiceUrl: string;
  isPaid: boolean;
  periodStart: string;
  periodEnd: string;
  productName: string;
  total: string;
}

export default function OrgInvoices() {
  const { orgId } = useParams();
  const { state } = useAuthState();
  const { data: orgData, loading: orgIsLoading, error: orgError } = useQuery<GetOrgProfileResponse>(GET_ORG_PROFILE, {
    variables: {
      orgId: orgId,
    },
  });
  const [getInvoices, { data: invoiceData, loading: invoicesAreLoading, error: invoiceError }] = useLazyQuery<GetInvoicesResponse>(GET_INVOICES);

  useEffect(() => {
    getInvoices({
      variables: {
        orgId: orgId,
        next: null,
        prev: null
      },
    });
  }, [getInvoices, orgId]);

  if (!state.user?.bsEmployeePermissions?.canLookupUsers || !state.user.bsEmployeePermissions.canViewOrgBillingDetails) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (orgIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (orgError || invoiceError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }
  
  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Orgs' to='/orgs' />
          <Breadcrumb label={orgData?.org?.name || ''} to={`/orgs/${orgId}`} />
          <Breadcrumb label='Invoices' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {invoicesAreLoading ?
          <AtomSpinner size='medium' />
          :
          <Card size='medium'>
            <View>
              {invoiceData?.invoicesForOrg?.length === 0 ?
                <StyledParagraph>No invoices found.</StyledParagraph>
                :
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Product</TableHeaderCell>
                      <TableHeaderCell>Billing Period</TableHeaderCell>
                      <TableHeaderCell>Total</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {invoiceData?.invoicesForOrg?.map((invoice, index) => (
                      <TableRow key={index}>
                        <TableCell>{invoice.productName}</TableCell>
                        <TableCell style={{ minWidth: 'fit-content', 'text-wrap': 'nowrap' }}>{invoice.periodStart} - {invoice.periodEnd}</TableCell>
                        <TableCell>{invoice.total}</TableCell>
                        <TableCell>{invoice.isPaid ? <span style={{ color: Colors.primary500 }}>PAID</span> : <span style={{ color: Colors.error500 }}>UNPAID</span>}</TableCell>
                        <TableCell style={{ minWidth: 'fit-content', 'text-wrap': 'nowrap' }}><Link href={invoice.hostedInvoiceUrl}>View Invoice</Link></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </View>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}