import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, FormModal, HasEmployeePermission, InfoPanel, Link, ModalLauncher, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useNavigate } from "react-router-dom";
import { IsValidSlug } from "../../util/validators";

/* Get Products Query */
const GET_PRODUCTS = gql`
query getAllProducts {
  products {
    id
    name
    description
    isPublic
  }
}
`;

type GetAllProductsResponse = {
  products: Product[] | null;
}

type Product = {
  id: string;
  name: string;
  description: string | null;
  isPublic: boolean;
}

/* Create Product Mutation */
const CREATE_PRODUCT = gql`
mutation createProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    name
    description
    isPublic
  }
}
`;

type CreateProductResponse = {
  createProduct: Product | null;
}

type CreateProductInput = {
  slug: string;
  name: string;
  description: string;
}

export default function AllProducts() {
  const nagivate = useNavigate();
  const { addAlert } = useAlertState();
  const { data: productsData, loading: productsAreLoading } = useQuery<GetAllProductsResponse>(GET_PRODUCTS);
  const [createProduct] = useMutation<CreateProductResponse>(CREATE_PRODUCT);

  const handleCreateProduct = async (values: CreateProductInput) => {
    const { data, errors } = await createProduct({
      variables: {
        input: {
          slug: values.slug,
          name: values.name,
          description: values.description ? values.description : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert id={id} title='Failed to create product' description={errors[0].message} type='error' />
      );

      addAlert(id, alert);
    }

    if (data?.createProduct) {
      nagivate(`/products/${data.createProduct.id}`);
    }
  }

  const handleValidateSlug = (_: string, value: string) => {
    if (!IsValidSlug(value)) {
      return 'Slug must only contain lowercase letters, numbers, and dashes.'
    }

    return null;
  }

  const createProductModal = (
    <FormModal<CreateProductInput> title='Create Product' submitLabel='Create' onSubmit={handleCreateProduct} initialValues={{ slug: '', name: '', description: '' }} >
      <View style={{ gap: '16px' }}>
        <TextField label='Slug' description='A unique identifier for the product' name='slug' validate={handleValidateSlug} required />
        <TextField label='Product Name' name='name' required />
        <TextArea label='Description' name='description' />
        <InfoPanel type='info'>
          <StyledParagraph>You must create a product in <span style={{ fontWeight: 600 }}>staging and production</span> with the same slug before adding the product to any code.</StyledParagraph>
        </InfoPanel>
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Products</StyledHeading>
          <HasEmployeePermission permissions={['canEditProducts']}>
            <ModalLauncher modal={createProductModal}>
              {({ openModal }) => (
                <Button label='Create Product' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasEmployeePermission>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {productsAreLoading ?
          <View>
            <AtomSpinner size='large' />
          </View>
          :
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell style={{ '@media (max-width: 767px)': { display: 'none' } }}>Description</TableHeaderCell>
                  <TableHeaderCell>Is Public</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {productsData?.products?.map((product, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ maxWidth: '50px', width: '50px' }}>{product.id}</TableCell>
                      <TableCell style={{ maxWidth: '200px', width: '200px' }}><Link href={`/products/${product.id}`}>{product.name}</Link></TableCell>
                      <TableCell style={{ '@media (max-width: 767px)': { display: 'none' } }}>{product.description}</TableCell>
                      <TableCell style={{ maxWidth: '100px', width: '100px' }}>{product.isPublic ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}