import { gql, useQuery } from "@apollo/client";
import { AutoOpen, Button, Card, Cell, Choice, CircularSpinner, Colors, DatePicker, FormModal, ModalLauncher, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableRow, View } from "@barscience/global-components";
import { useState } from "react";

const GET_NEW_ORGS = gql`
query getRecentlyCreatedOrgs($createdAfter: Timestamp!, $createdBefore: Timestamp!) {
  newOrgs(createdAfter: $createdAfter, createdBefore: $createdBefore) {
    id
    name
    address {
      streetAddress
      city
      state
      zipcode
    }
    created
  }
}
`;

type NewOrgsResponse = {
  newOrgs: Org[];
}

type Org = {
  id: string;
  name: string;
  address: {
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
  };
  created: string;
}

type DateRange = {
  startDate: string;
  endDate: string;
}

type SelectedRange = 'CUSTOM' | 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'SET_CUSTOM';

export default function NewOrgs() {
  const [dateRange, setDateRange] = useState<DateRange | null>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
    endDate: new Date().toISOString(),
  });
  const [selectedRange, setSelectedRange] = useState<SelectedRange | null>('LAST_7_DAYS');
  const { data, loading } = useQuery<NewOrgsResponse>(GET_NEW_ORGS, {
    variables: {
      createdAfter: dateRange?.startDate || '',
      createdBefore: dateRange?.endDate || '',
    },
  });

  const handleDateFilterChange = (_: string, value: string | null) => {
    setSelectedRange(value as SelectedRange);

    if (value === 'LAST_7_DAYS') {
      setDateRange({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
        endDate: new Date().toISOString(),
      });
    } else if (value === 'LAST_30_DAYS') {
      setDateRange({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
        endDate: new Date().toISOString(),
      });
    }
  }

  const handleDateRangePick = async (values: DateRange) => {
    setDateRange({
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
    });

    setSelectedRange('CUSTOM');
  }

  const handleCancelDateRangePick = () => {
    setSelectedRange('LAST_7_DAYS');
    setDateRange({
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
      endDate: new Date().toISOString(),
    });
  }

  const getDateLabel = (): string => {
    if (!dateRange) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);

    const label = `${months[start.getMonth()]} ${start.getDate()}${getDayLabel(start.getDate())} - ${months[end.getMonth()]} ${end.getDate()}${getDayLabel(end.getDate())}`;

    return label;
  }

  const getDayLabel = (day: number) => {
    const str = day.toString();
    if (str.charAt(str.length - 1) === '1') {
      return 'st';
    } else if (str.charAt(str.length - 1) === '2') {
      return 'nd';
    } if (str.charAt(str.length - 1) === '3') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const customRangeModal = (
    <FormModal<DateRange> title='Select date range' onSubmit={handleDateRangePick} onCancel={handleCancelDateRangePick} showCloseButton={false} submitLabel='Confirm' initialValues={{ startDate: dateRange?.startDate || '', endDate: dateRange?.endDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Start Date' name='startDate' required />
        <DatePicker label='End Date' name='endDate' required />
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <ModalLauncher modal={customRangeModal}>
        {({ openModal: openPickDateModal }) => {
          if (selectedRange === 'SET_CUSTOM') {
            return <AutoOpen openModal={openPickDateModal} />
          }

          return <></>
        }}
      </ModalLauncher>

      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>New Org Signups</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <SingleSelect name='dateRange' value={selectedRange} onChange={handleDateFilterChange} style={{ maxWidth: '255px', width: '225px' }}>
          <Choice label='Last 7 days' value='LAST_7_DAYS' />
          <Choice label='Last 30 days' value='LAST_30_DAYS' />
          {(dateRange && selectedRange === 'CUSTOM') && <Choice label={getDateLabel()} value='CUSTOM' />}
          <View style={{ borderTop: `1px solid ${Colors.neutral200}`, marginTop: '4px', paddingTop: '4px' }}>
            <Choice label='Custom Range' value='SET_CUSTOM' />
          </View>
        </SingleSelect>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {loading ?
          <View style={{ alignItems: 'center' }}>
            <CircularSpinner size='medium' />
          </View>
          :
          <>
            {data?.newOrgs.length === 0 ?
              <StyledParagraph>No new orgs found</StyledParagraph>
              :
              <Card>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data?.newOrgs.map(org => (
                      <TableRow key={org.id}>
                        <TableCell>{org.name}</TableCell>
                        <TableCell>{org.address.streetAddress}, {org.address.city}, {org.address.state} {org.address.zipcode}</TableCell>
                        <TableCell>{org.created}</TableCell>
                        <TableCell>
                          <Button label='View' variant='tertiary' role='link' href={`/orgs/${org.id}`} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            }
          </>
        }

      </Cell>
    </StandardGrid>
  );
}