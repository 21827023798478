import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Colors, ConfirmModal, ErrorPage, generateId, Icon, Icons, ModalLauncher, NoPermission, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Typography, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useParams } from "react-router-dom";
import { GET_ORG_PROFILE, GetOrgProfileResponse } from "./OrgUsers";
import { css, StyleSheet } from "aphrodite";

/* Get Payment Methods Query */
const GET_PAYMENT_METHODS = gql`
query getPaymentMethodsForOrg($orgId: ID!) {
  paymentMethodsForOrg(orgId: $orgId) {
    id
    created
    isDefault
    method {
      ... on CardPaymentMethod {
        brand
        country
        expirationMonth
        expirationYear
        last4
      }
    }
  }
}
`;

type GetPaymentMethodsResponse = {
  paymentMethodsForOrg: PaymentMethod[] | null;
}

type PaymentMethod = {
  id: string;
  created: string;
  isDefault: boolean;
  method: CardPaymentMethod | null;
}

type CardPaymentMethod = {
  brand: CardBrand;
  country: string;
  expirationMonth: number;
  expirationYear: number;
  last4: string;
  __typename: "CardPaymentMethod";
}

type CardBrand = 'amex' | 'diners' | 'discover' | 'eftpos_au' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown';

/* Set Default Payment Method Mutation */
const SET_DEFAULT_PAYMENT_METHOD = gql`
mutation setDefaultPaymentMethodForOrg($orgId: ID!, $paymentMethodId: ID!) {
  setDefaultPaymentMethod(orgId: $orgId, paymentMethodId: $paymentMethodId) {
    id
    isDefault
  }
}
`;

type SetDefaultPaymentMethodResponse = {
  setDefaultPaymentMethod: {
    id: string;
    isDefault: boolean;
  } | null;
}

/* Delete Payment Method Mutation */
const DELETE_PAYMENT_METHOD = gql`
mutation deletePaymentMethod($orgId: ID!, $paymentMethodId: ID!) {
  deletePaymentMethod(orgId: $orgId, paymentMethodId: $paymentMethodId)
}
`;

type DeletePaymentMethodResponse = {
  deletePaymentMethod: boolean;
}

const styles = StyleSheet.create({
  defaultLabel: {
    color: Colors.primary500,
    fontWeight: 600,
  }
});

export default function OrgPaymentMethods() {
  const { orgId } = useParams();
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: orgData, loading: orgIsLoading, error: orgError } = useQuery<GetOrgProfileResponse>(GET_ORG_PROFILE, {
    variables: {
      orgId: orgId,
    },
  });
  const { data: paymentMethodsData, loading: paymentMethodsAreLoading, error: paymentMethodsError } = useQuery<GetPaymentMethodsResponse>(GET_PAYMENT_METHODS, {
    variables: {
      orgId: orgId,
    },
  });
  const [setDefaultPaymentMethod] = useMutation<SetDefaultPaymentMethodResponse>(SET_DEFAULT_PAYMENT_METHOD, {
    update(cache, { data }) {
      if (!data?.setDefaultPaymentMethod?.isDefault) {
        return;
      }

      cache.writeQuery<GetPaymentMethodsResponse>({
        query: GET_PAYMENT_METHODS,
        variables: {
          orgId: orgId,
        },
        data: {
          paymentMethodsForOrg: paymentMethodsData?.paymentMethodsForOrg?.map((paymentMethod) => {
            if (paymentMethod.id === data.setDefaultPaymentMethod?.id) {
              return {
                ...paymentMethod,
                isDefault: true,
              };
            }

            return {
              ...paymentMethod,
              isDefault: false,
            };
          }) || [],
        },
      });
    },
  });
  const [deletePaymentMethod] = useMutation<DeletePaymentMethodResponse>(DELETE_PAYMENT_METHOD, {
    update(cache, { data }, { variables }) {
      if (!data?.deletePaymentMethod || !variables?.paymentMethodId) {
        return;
      }

      cache.writeQuery<GetPaymentMethodsResponse>({
        query: GET_PAYMENT_METHODS,
        variables: {
          orgId: orgId,
        },
        data: {
          paymentMethodsForOrg: paymentMethodsData?.paymentMethodsForOrg?.filter((paymentMethod) => paymentMethod.id !== variables.paymentMethodId) || [],
        },
      });
    },
  });

  const handleSetDefaultPaymentMethod = async (paymentMethodId: string) => {
    const { errors } = await setDefaultPaymentMethod({
      variables: {
        paymentMethodId: paymentMethodId,
        orgId: orgId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to change default payment method' description={errors[0].message} type='error' id={id} />
      );

      addAlert(id, alert);
    }
  }

  const handleDeletePaymentMethod = async (paymentMethodId: string) => {
    const { errors } = await deletePaymentMethod({
      variables: {
        paymentMethodId: paymentMethodId,
        orgId: orgId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to delete payment method' description={errors[0].message} type='error' id={id} />
      );

      addAlert(id, alert);
    }
  }

  const deletePaymentMethodModal = (
    <ConfirmModal title='Delete Payment Method?' confirmLabel='Delete' destructive onConfirm={handleDeletePaymentMethod}>
      <StyledParagraph>This payment method will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  if (!state.user?.bsEmployeePermissions?.canLookupUsers || !state.user.bsEmployeePermissions.canViewOrgBillingDetails) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (orgIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (paymentMethodsError || orgError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Orgs' to='/orgs' />
          <Breadcrumb label={orgData?.org?.name || ''} to={`/orgs/${orgId}`} />
          <Breadcrumb label='Payment Methods' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {paymentMethodsAreLoading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ gap: '16px' }}>
            {paymentMethodsData?.paymentMethodsForOrg?.length === 0 ?
              <View style={{ alignItems: 'center', gap: '16px', height: '60vh', justifyContent: 'center' }}>
                <img src='/illustrations/credit-card.svg' width='30%' alt='Credit card illustration' />
                <StyledHeading tag='h5'>No payment methods found</StyledHeading>
              </View>
              :
              paymentMethodsData?.paymentMethodsForOrg?.map((paymentMethod, index) => (
                <Card size='medium' key={index} style={{ maxWidth: '400px' }}>
                  <View>
                    <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                        <Icon icon={getCardIcon(paymentMethod.method?.brand)} size='large' style={{ height: '60px', width: '60px' }} />
                        <StyledParagraph bold>Ending in {paymentMethod.method?.last4}</StyledParagraph>
                      </View>

                      {(!paymentMethod.isDefault && state.user?.bsEmployeePermissions?.canEditOrgBillingDetails) &&
                        <ModalLauncher modal={deletePaymentMethodModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu alignment='right'>
                              {!paymentMethod.isDefault && <ActionItem label='Set as default' onClick={() => { handleSetDefaultPaymentMethod(paymentMethod.id); }} />}
                              <ActionItem label='Delete' onClick={() => { openDeleteModal(paymentMethod.id); }} />
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      }
                    </View>
                    <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                      <StyledParagraph style={{ color: Colors.neutral700 }}>Expires {paymentMethod.method?.expirationMonth}/{paymentMethod.method?.expirationYear}</StyledParagraph>
                      {paymentMethod.isDefault && <span className={css(Typography.paragraph2, styles.defaultLabel)}>Default</span>}
                    </View>
                  </View>
                </Card>
              ))}
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}

const getCardIcon = (brand: CardBrand | undefined) => {
  switch (brand) {
    case 'amex':
      return Icons.Amex;
    case 'diners':
      return Icons.DinersClub;
    case 'discover':
      return Icons.Discover;
    case 'jcb':
      return Icons.JCB;
    case 'mastercard':
      return Icons.Mastercard;
    case 'visa':
      return Icons.Visa;
    default:
      return Icons.CreditCard;
  }
}