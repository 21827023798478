import { gql, useLazyQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Choice, Link, NoPermission, SingleSelect, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextField, View, useAuthState, useForm } from "@barscience/global-components";
import { useSearchParams } from "react-router-dom";

/* Org Search Query */
const ORG_SEARCH_QUERY = gql`
query searchOrgs($input: OrgSearchInput!, $page: Int!) {
  orgSearch(input: $input, page: $page) {
    id
    name
    address {
      streetAddress
      city
      state
      zipcode
    }
    created
  }
}
`;

type OrgSearchResponse = {
  orgSearch: Org[];
}

type Org = {
  id: string;
  name: string;
  address: {
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
  };
  created: string;

}

type SearchInput = {
  query: string;
  type: SearchInputType;
}

type SearchInputType = 'ID' | 'NAME' | 'STREET_ADDRESS' | 'ZIPCODE';

export default function OrgSearch() {
  const { state } = useAuthState();
  const [searchParams, setSearchParams] = useSearchParams({
    type: 'NAME',
  });
  const [search, { data: searchData, loading: searchIsLoading }] = useLazyQuery<OrgSearchResponse>(ORG_SEARCH_QUERY, {
    errorPolicy: 'all',
  });

  const searchForm = useForm<SearchInput>({
    initialValues: {
      query: searchParams.get('query') || '',
      type: searchParams.get('type') as SearchInputType || 'NAME',
    },
    onSubmit: async (values) => {
      await search({
        variables: {
          input: {
            query: values.query,
            type: values.type,
          },
          page: 0,
        },
      });
    },
  });

  const handleChangeType = (newType: SearchInputType) => {
    setSearchParams({
      type: newType,
      query: searchForm.values.query,
    });
  }

  if (!state.user?.bsEmployeePermissions?.canLookupUsers) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
          <StyledHeading tag='h3'>Org Search</StyledHeading>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Link href='/new-orgs'>View recently created orgs</Link>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
            <SingleSelect label='Search By' name='type' value={searchForm.values.type} error={searchForm.errors.type} onChange={(name, value) => { handleChangeType(value as SearchInputType || 'NAME'); searchForm.handleChange(name, value); }} onValidate={searchForm.handleValidate} style={{ maxWidth: '200px' }}>
              <Choice label='Name' value='NAME' />
              <Choice label='ID' value='ID' />
              <Choice label='Street Address' value='STREET_ADDRESS' />
              <Choice label='Zipcode' value='ZIPCODE' />
            </SingleSelect>

            <TextField label='' name='query' placeholder='Enter search query' value={searchForm.values.query} error={searchForm.errors.query} onChange={searchForm.handleChange} onBlur={(_, value) => { setSearchParams({ type: searchForm.values.type, query: value }); }} onValidate={searchForm.handleValidate} style={{ maxWidth: '500px', '@media (max-width: 767px)': { maxWidth: '100%', width: '100%' } }} />

            <Button label='Search' variant='primary' role='button' action={searchForm.handleSubmit} disabled={searchForm.hasError ||  searchForm.values.query === ''} loading={searchIsLoading} />
          </View>
        </Card>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {searchIsLoading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ gap: '16px' }}>
            {searchData ?
              searchData.orgSearch?.map((org) => (
                <Card size='medium' key={org.id} style={{ maxWidth: '600px' }}>
                  <View style={{ gap: '16px' }}>
                    <Link href={'/orgs/' + org.id}><StyledHeading tag='h6'>{org.name}</StyledHeading></Link>

                    <View>
                      <StyledCaption>ID</StyledCaption>
                      <StyledParagraph>{org.id}</StyledParagraph>
                    </View>

                    <View>
                      <StyledCaption>Address</StyledCaption>
                      <StyledParagraph>{org.address.streetAddress}</StyledParagraph>
                      <StyledParagraph>{org.address.city}, {org.address.state} {org.address.zipcode}</StyledParagraph>
                    </View>

                    <View>
                      <StyledCaption>Created</StyledCaption>
                      <StyledParagraph>{org.created ? (new Date(org.created).toLocaleString()) : '----'}</StyledParagraph>
                    </View>
                  </View>
                </Card>
              ))
              :
              <></>
            }
            {(searchData?.orgSearch?.length === 0 || searchData?.orgSearch === null) && <StyledParagraph>No orgs found.</StyledParagraph>}
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}