import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Button, Card, Cell, Checkbox, Choice, Colors, ConfirmModal, DatePicker, FormModal, HasEmployeePermission, Link, ModalLauncher, NoPermission, PageButtons, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, useAuthState } from "@barscience/global-components";
import { useState } from "react";

/* Get Errors Query */
const GET_ALL_ERRORS = gql`
query getAllDebugErrors($page: Int!, $sort: DebugErrorSortOrder, $service: DebugErrorService, $userId: ID, $dateRange: DateRange) {
  debugErrors(page: $page, sort: $sort, service: $service, userId: $userId, dateRange: $dateRange) {
    id
    description
    timestamp
    user {
      id
      firstName
      lastName
    }
    service
  }
}
`;

type GetAllErrorsResponse = {
  debugErrors: DebugError[] | null;
}

type DebugError = {
  id: number;
  description: string;
  timestamp: string | null;
  user: User | null;
  service: string | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
}

type DateRange = {
  startDate: string;
  endDate: string;
}

/* Bulk Delete Errors Mutation */
const BULK_DELETE_ERRORS = gql`
mutation bulkDeleteDebugError($ids: [Int!]!) {
  success: bulkDeleteDebugErrors(ids: $ids)
}
`;

type BulkDeleteErrorsResponse = {
  success: boolean;
}

export default function AllErrors() {
  const { state } = useAuthState();
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<string>('NEWEST_FIRST');
  const [service, setService] = useState<string | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | null>('ALL_TIME');
  const [errorsToDelete, setErrorsToDelete] = useState<number[]>([]);
  const { data: errorData, loading: errorsAreLoading, refetch: refetchErrors } = useQuery<GetAllErrorsResponse>(GET_ALL_ERRORS, {
    variables: {
      page: page,
      sort: sort,
      service: service,
      userId: userId ? userId : null,
      dateRange: selectedRange === 'CUSTOM' ? dateRange : null,
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setErrorsToDelete([]);
    },
  });
  const [deleteErrors] = useMutation<BulkDeleteErrorsResponse>(BULK_DELETE_ERRORS);

  const handleDateFilterChange = (_: string, value: string | null) => {
    setSelectedRange(value);
  }

  const handleDateRangePick = async (values: DateRange) => {
    setDateRange({
      startDate: values.startDate,
      endDate: values.endDate,
    });

    setSelectedRange('CUSTOM');
  }

  const handleCancelDateRangePick = () => {
    setSelectedRange('ALL_TIME');
  }

  const getDateLabel = (): string => {
    if (!dateRange) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);

    const label = `${months[start.getMonth()]} ${start.getDate()}${getDayLabel(start.getDate())} - ${months[end.getMonth()]} ${end.getDate()}${getDayLabel(end.getDate())}`;

    return label;
  }

  const getDayLabel = (day: number) => {
    const str = day.toString();
    if (str.charAt(str.length - 1) === '1') {
      return 'st';
    } else if (str.charAt(str.length - 1) === '2') {
      return 'nd';
    } if (str.charAt(str.length - 1) === '3') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const handleDeleteErrors = async () => {
    const { data } = await deleteErrors({
      variables: {
        ids: errorsToDelete,
      },
    });

    if (data?.success) {
      refetchErrors();
    }
  }

  const handleDeleteCheckboxChange = (name: string, value: boolean) => {
    if (value) {
      let errors = [...errorsToDelete, parseInt(name)];
      setErrorsToDelete(errors);
    } else {
      let errors = [...errorsToDelete];
      errors = errors.filter((id) => id !== parseInt(name));
      setErrorsToDelete(errors);
    }
  }

  const handleSelectAllErrors = () => {
    let errors: number[] = [];

    errorData?.debugErrors?.forEach((e) => {
      errors.push(e.id);
    });

    setErrorsToDelete(errors);
  }

  const handleSelectNoErrors = () => {
    setErrorsToDelete([]);
  }

  const customRangeModal = (
    <FormModal<DateRange> title='Select date range' onSubmit={handleDateRangePick} onCancel={handleCancelDateRangePick} showCloseButton={false} submitLabel='Confirm' initialValues={{ startDate: dateRange?.startDate || '', endDate: dateRange?.endDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Start Date' name='startDate' required />
        <DatePicker label='End Date' name='endDate' required />
      </View>
    </FormModal>
  );

  const confirmDeleteModal = (
    <ConfirmModal title='Bulk Delete Errors?' onConfirm={handleDeleteErrors} confirmLabel={`Delete ${errorsToDelete.length} Error${errorsToDelete.length !== 1 ? 's' : ''}`} destructive>
      <StyledParagraph>These errors will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  if (!state.user?.bsEmployeePermissions?.canViewLoggedErrors) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <ModalLauncher modal={customRangeModal}>
        {({ openModal: openPickDateModal }) => {
          if (selectedRange === 'SET_CUSTOM') {
            return <AutoOpen openModal={openPickDateModal} />
          }

          return <></>
        }}
      </ModalLauncher>

      <Cell lg={12} md={8} sm={4}>
        <View>
          <StyledHeading tag='h3'>Debug Errors</StyledHeading>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <SingleSelect label='Order By' name='sort' value={sort} onChange={(_, val) => { setSort(val || 'NEWEST_FIRST'); }} style={{ minWidth: '150px', width: '150px' }} >
            <Choice label='Newest' value='NEWEST_FIRST' />
            <Choice label='Oldest' value='OLDEST_FIRST' />
          </SingleSelect>
          <SingleSelect label='Date Range' name='dateRange' value={selectedRange} onChange={handleDateFilterChange} style={{ maxWidth: '255px', minWidth: '255px', width: '225px' }}>
            <Choice label='All Time' value='ALL_TIME' />
            {dateRange && <Choice label={getDateLabel()} value='CUSTOM' />}
            <View style={{ borderTop: `1px solid ${Colors.neutral200}`, marginTop: '4px', paddingTop: '4px' }}>
              <Choice label='Custom Range' value='SET_CUSTOM' />
            </View>
          </SingleSelect>
          <SingleSelect label='Service' name='service' value={service} onChange={(_, val) => { setService(val); }} style={{ minWidth: '150px', width: '150px' }} >
            <Choice label='All' value={null} />
            <Choice label='Admin' value='ADMIN' />
            <Choice label='Auth' value='AUTH' />
            <Choice label='Inventory' value='INVENTORY' />
            <Choice label='Users' value='USERS' />
          </SingleSelect>
          <HasEmployeePermission permissions={['canLookupUsers']}>
            <TextField label='User ID' name='user' value={userId} onChange={(_, val) => { setUserId(val || ''); }} />
          </HasEmployeePermission>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between' }}>
          <HasEmployeePermission permissions={['canDeleteLoggedErrors']}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
              <Button label='Select All' role='button' action={handleSelectAllErrors} variant='secondary' size='small' />
              <Button label='Select None' role='button' action={handleSelectNoErrors} variant='secondary' size='small' />
              <ModalLauncher modal={confirmDeleteModal}>
                {({ openModal }) => (
                  <Button label='Delete' role='button' action={openModal} variant='primary' size='small' destructive disabled={errorsToDelete.length === 0} />
                )}
              </ModalLauncher>
              <StyledParagraph>{errorsToDelete.length} error{errorsToDelete.length !== 1 ? 's' : ''} selected</StyledParagraph>
            </View>
          </HasEmployeePermission>
          <PageButtons currentPage={page} numPages={100} onPageChange={setPage} />
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {errorsAreLoading ?
            <AtomSpinner size='medium' />
            :
            <View>
              {(errorData?.debugErrors?.length || 0) > 0 ?
                <Table>
                  <TableHeader>
                    <TableRow>
                      <HasEmployeePermission permissions={['canDeleteLoggedErrors']}>
                        <TableHeaderCell></TableHeaderCell>
                      </HasEmployeePermission>
                      <TableHeaderCell>ID</TableHeaderCell>
                      <TableHeaderCell>Timestamp</TableHeaderCell>
                      <TableHeaderCell style={{ minWidth: '300px' }}>Description</TableHeaderCell>
                      <TableHeaderCell>Service</TableHeaderCell>
                      <HasEmployeePermission permissions={['canLookupUsers']}>
                        <TableHeaderCell style={{ minWidth: '150px' }}>User</TableHeaderCell>
                      </HasEmployeePermission>
                      <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {errorData?.debugErrors?.map((e, index) => {
                      return (
                        <TableRow key={index}>
                          <HasEmployeePermission permissions={['canDeleteLoggedErrors']}>
                            <TableCell>
                              <Checkbox name={e.id.toString()} checked={errorsToDelete.includes(e.id)} onChange={handleDeleteCheckboxChange} />
                            </TableCell>
                          </HasEmployeePermission>
                          <TableCell>{e.id}</TableCell>
                          <TableCell>{e.timestamp || 'Unknown'}</TableCell>
                          <TableCell>{e.description}</TableCell>
                          <TableCell>{e.service || 'Unknown'}</TableCell>
                          <HasEmployeePermission permissions={['canLookupUsers']}>
                            <TableCell>{e.user ? <Link href={`/users/${e.user.id}`}>{e.user.firstName} {e.user.lastName}</Link> : 'Unkown'}</TableCell>
                          </HasEmployeePermission>
                          <TableCell><Button label='View' role='link' href={`/errors/${e.id}`} variant='tertiary' /></TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                :
                <StyledParagraph>No errors found.</StyledParagraph>
              }
            </View>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}